const storeToken = (value) => {
  if (value) {
    localStorage.setItem("access", JSON.stringify(value.access));
    localStorage.setItem("refresh", JSON.stringify(value.refresh));
  }
}
const storeResponse = (value) => {
  if (value) {
      localStorage.setItem('user_type', value.user_type)
      localStorage.setItem('com_id', value.com_id)
      localStorage.setItem('com_name', value.com_name)

  }
}
const getToken = () => {
  let access_token = localStorage.getItem('access')
  let refresh_token = localStorage.getItem('refresh')
  return { access_token, refresh_token }
}

const removeToken = () => {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
  localStorage.removeItem('com_name')
  localStorage.removeItem('com_id')
  localStorage.removeItem('user_type')

}

export { storeToken, getToken, removeToken ,storeResponse}