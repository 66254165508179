import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin, message, Row, Col, Select, Tooltip, Upload } from "antd";
import { postInsurance, getVehiclelist, getInsuranceByVehicleId } from '../../../Services/Services';
import { FaPlusSquare } from "react-icons/fa";
import { UploadOutlined } from '@ant-design/icons';
const AddInsurance = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');
    const [vehicle, setVehicle] = useState([]);
    useEffect(() => {
        GetVehicle();
    }, []);

    const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
            setVehicle(response);
        }
    }
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values, { com_id: localStorage.getItem('com_id') })
        const response = await postInsurance(values);
        setLoading(false);
        if (response) {
            message.success('Insurance Documents Creation Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.insurance_doc_id - a.insurance_doc_id);
            props.setinsurance(sortedArray);
            props.setfilterInsuarnce(sortedArray)
            form.resetFields();
        } else {
            message.warning('Insurance Documents Creation Not Successful');
        }
    };

    const GetFitnessDoc = async (vehicle_id) => {
        const response = await getInsuranceByVehicleId(vehicle_id);
        if (response) {
            form.setFieldsValue({
                insurance_no: response.insurance_no,
                insurance_amount: response.insurance_amount,
                ins_company: response.ins_company,
                ins_type: response.ins_type,
                valid_from: response.valid_from,
                expiry_date: response.expiry_date,
            })
        }

    }

    const validateToNextDate = async (_, value) => {
        const validFrom = form.getFieldValue('valid_from');
        if (value && validFrom && value <= validFrom) {
            throw new Error('Valid To date must be after Valid From date');
        }
    };
    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Insurance Documents Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add Insurance Documents'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            // width={1000}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="vehicle_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select Vehicle No!",
                                    },
                                ]}
                                label={<b>Select Vehicle No</b>}
                                style={{ padding: '0px', margin: "1px" }}
                            >
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    onChange={GetFitnessDoc}
                                >
                                    {vehicle.map((e) => (
                                        <Select.Option value={e.vehicle_id}>
                                            {e.vehicle_no}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item
                                name="insurance_no"
                                label={<b>Insurance No</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Insurance No!",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Insurance No" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item
                                name="insurance_amount"
                                label={<b>Insurance Amount</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Insurance Amount!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Insurance Amount' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ins_company"
                                label={<b>Insurance Company</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Insurance Company!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Insurance Company!' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                     
                        <Col span={12}>
                            <Form.Item
                                name="ins_type"
                                label={<b>Insurance Type</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Insurance Type!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Insurance Type!' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="picture"
                                label={<label className='font-bold' style={{ fontSize: "14px" }}>Documents Picture</label>}
                                getValueFromEvent={({ file }) => file.originFileObj}

                            >
                                <Upload  accept="image/*"  >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="valid_from"
                                label={<b>Valid From</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Valid From!",
                                    },
                                ]}
                            >
                                <Input type='date' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item
                                name="expiry_date"
                                label={<b>Valid To</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Valid To Date!",
                                    },
                                    { validator: validateToNextDate }
                                ]}
                            >
                                <Input type='date' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddInsurance;
