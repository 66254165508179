import React, { useState, useEffect, useMemo } from 'react';
import { getVehiclelist, getYearlyMonthlyExpense } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Select, message,Modal,Image } from 'antd';
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import config from '../../config';
import { saveAs } from 'file-saver';
import { CiMail } from "react-icons/ci";
import { FaFileExcel, FaDownload } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
export default function MonthlyExpenseRpt() {
  const [arrow, setArrow] = useState('Show');
  const [vehicle, setVehicle] = useState([]);
  const [expenseList, setexpenseList] = useState([]);
  const [vehicleId, setVehicleId] = useState(null)
  const { form } = Form.useForm()
  useEffect(() => {
    GetVehicle();
  }, []);
  const GetVehicle = async () => {
    const response = await getVehiclelist();
    if (response) {
      setVehicle(response);
    }
  }

  const GetExpenseByMonth = async (month) => {
    setexpenseList([]);
    if (vehicleId === null) {
      message.warning('Please Select Vehicle No')
    } else {
      const response = await getYearlyMonthlyExpense({
        vehicle_id: vehicleId,
        month: month,
        type: 'monthly'
      });
      if (response) {
        setexpenseList(response);
      }
    }


  }
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = expenseList.map((e, index) => [
      index + 1,
      e.vehicle_no,
      `${e.from_city} - ${e.to_city}`,
      e.amount,
      e.check_no,
      e.check_date,
      e.expense_type,
      e.descriptions,
      convertDatetimeFormat(e.created_at),
    
    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No', 'Trip Details', 'Amount', 'Check No','Check Date','Expense Type','Descriptions', 'Date'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1','E1','G1','I1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Modal No.
      { wch: 20 },  // Chasis No
      { wch: 10 },  // GPS
      { wch: 15 },  // Vehicle Status
      { wch: 20 },  // Chasis No
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Monthly Expense');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Monthly Expense.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const convertDatetimeFormat = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString('en-US');
    return `${formattedDate} ${formattedTime}`;
  }
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false)
  };
  const [docPath, setdocpath] = useState('')
  const handleDocuments = (path) => {
    setOpen(true)
    setdocpath(path)
  }
  const shareByEmail = (imageUrl) => {
    const subject = 'Check out this image!';
    const body = 'Hey, I thought you might like this image!';
    const dataUrl = imageUrl;

    const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(dataUrl)}`;

    window.location.href = mailToLink;
  };
  return (
    <div >
          <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        width={380}
      >
        <Image src={docPath} width={300} alt='img' />
        <div style={{ textAlign: "center" }}>
          <Tooltip
            placement="top"
            title={'Download'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => saveAs(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px",
                marginRight: "20px"
              }}
            >
              <FaDownload

              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={'Mail Image'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => shareByEmail(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px"
              }}
            >
              <CiMail />
            </button>
          </Tooltip>
        </div>
      </Modal>
      <Form
        form={form}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            {/* <label><b>Party Name</b></label><br /> */}
            <Form.Item name='vehicle_no'>
              <Select
                showSearch
                placeholder="Select Vehicle No"
                optionFilterProp="children"
                onChange={(value) => setVehicleId(value)}
              >
                {vehicle.map((e) => (
                  <Select.Option value={e.vehicle_id}>
                    {e.vehicle_no}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col>
            {/* <label><b>Pan Number</b></label><br /> */}
            <Form.Item name='month'>
              <Select
                showSearch
                placeholder="Select Month"
                optionFilterProp="children"
                onChange={GetExpenseByMonth}
              >

                <Select.Option value={1}>January</Select.Option>
                <Select.Option value={2}>February</Select.Option>
                <Select.Option value={3}>March</Select.Option>
                <Select.Option value={4}>April</Select.Option>
                <Select.Option value={5}>May</Select.Option>
                <Select.Option value={6}>June</Select.Option>
                <Select.Option value={7}>July</Select.Option>
                <Select.Option value={8}>August</Select.Option>
                <Select.Option value={9}>September</Select.Option>
                <Select.Option value={10}>October</Select.Option>
                <Select.Option value={11}>November</Select.Option>
                <Select.Option value={12}>December</Select.Option>

              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Trip Details</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Amount</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Check No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Check Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Descriptions</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Expense Type</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Date/Time</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Picture</th>
            </tr>
          </thead>
          <tbody>

            {expenseList.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.from_city}- {e.to_city}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.amount}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.check_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.check_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.descriptions}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.expense_type}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{convertDatetimeFormat(e.created_at)}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  <Tooltip
                    placement="top"
                    title={e.picture===null ? '' :'View Documents'}
                    arrow={mergedArrow}
                  >
                    <Button
                      onClick={() => handleDocuments(e.picture)}
                      style={{
                        backgroundColor: e.picture===null ? 'white' :'#1B4F72',
                        color: e.picture===null ? 'black' :'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"
                      
                      }}
                      disabled={e.picture===null ? true :false}
                    >
                      <IoEyeSharp

                      />
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            ))}
            <tr style={{ backgroundColor: '#e9e9e9' }}>
              <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" colSpan={3}><b>Total</b></td>
              <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                <b>{expenseList.reduce(
                  (accumulator, currentValue) =>
                    accumulator + (+currentValue.amount),
                  0
                ).toFixed(3)}</b></td>
              <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" colSpan={6} ></td>
            </tr>
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
