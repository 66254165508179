import React, { useState, useEffect, useMemo } from 'react';
import { getVehiclelist, documents } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Select, message ,Modal,Image} from 'antd';
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import config from '../../config';
import { saveAs } from 'file-saver';
import { CiMail } from "react-icons/ci";
import { FaFileExcel, FaDownload } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
export default function VehicleDocument() {
  const [arrow, setArrow] = useState('Show');
  const [vehicle, setVehicle] = useState([]);
  const [list, setList] = useState([])
  const { form } = Form.useForm()
  useEffect(() => {
    GetVehicle();
  }, []);
  const GetVehicle = async () => {
    const response = await getVehiclelist();
    if (response) {
      setVehicle(response);
    }
  }

  const getDocuments = async (vehicle_id) => {
    const response = await documents(vehicle_id);
    if (response) {
      console.log(response, 'res')
      setList(response);
    }
  }



  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const getYearsArray = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };
  const currentYear = new Date().getFullYear();
  const years = getYearsArray(2023, currentYear);

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = [].map((e, index) => [
      index + 1,
      e.client_name,
      e.contact_no,
      e.pan_no,
      e.gst_no,
      e.Address,
      e.moisture_per
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Party Name', 'Contact No.', 'Gst No', 'Pan No', 'Address', 'Moisture %'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'DeliveryOrder');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'PartyList.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const convertDatetimeFormat = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString('en-US');
    return `${formattedDate} ${formattedTime}`;
  }
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false)
  };
  const [docPath, setdocpath] = useState('')
  const handleDocuments = (path) => {
    setOpen(true)
    setdocpath(path)
  }
  const shareByEmail = (imageUrl) => {
    const subject = 'Check out this image!';
    const body = 'Hey, I thought you might like this image!';
    const dataUrl = imageUrl;

    const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(dataUrl)}`;

    window.location.href = mailToLink;
  };
  return (
    <div >
         <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        width={380}
      >
        <Image src={docPath} width={300} alt='img' />
        <div style={{ textAlign: "center" }}>
          <Tooltip
            placement="top"
            title={'Download'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => saveAs(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px",
                marginRight: "20px"
              }}
            >
              <FaDownload

              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={'Mail Image'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => shareByEmail(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px"
              }}
            >
              <CiMail />
            </button>
          </Tooltip>
        </div>
      </Modal>
      <Form
        form={form}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            {/* <label><b>Party Name</b></label><br /> */}
            <Form.Item name='vehicle_no'>
              <Select
                showSearch
                placeholder="Select Vehicle No"
                optionFilterProp="children"
                onChange={getDocuments}
              >
                {vehicle.map((e) => (
                  <Select.Option value={e.vehicle_id}>
                    {e.vehicle_no}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          <Col>
            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <table id="my-table" className="table-container" ref={componentRef}  >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Documents No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Documents Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Amount</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">From Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">To Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Created Date Time</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Picture</th>
            </tr>
          </thead>
          <tbody>

            {list.fitness && [list.fitness].map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.fitness_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Fitness</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.fitness_amount}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.valid_from}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.expiry_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{convertDatetimeFormat(e.created_at)}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  <Tooltip
                    placement="top"
                    title={e.picture===null ? '' :'View Documents'}
                    arrow={mergedArrow}
                  >
                    <Button
                      onClick={() => handleDocuments(e.picture)}
                      style={{
                        backgroundColor: e.picture===null ? 'white' :'#1B4F72',
                        color: e.picture===null ? 'black' :'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"
                      
                      }}
                      disabled={e.picture===null ? true :false}
                    >
                      <IoEyeSharp

                      />
                    </Button>
                  </Tooltip>
                </td>
              </tr>
              
            ))}
               
       
            
          </tbody>
        </table>
      </div>
    </div>
  )
}
