import React, { useState, useEffect, useMemo } from 'react';
import { getVehiclelist } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, } from 'antd';
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineDone } from "react-icons/md";
export default function DocumentsStatus() {
  const [arrow, setArrow] = useState('Show');
  const [vehicle, setVehicle] = useState([]);
  const [filterVihilce, setfilterVihilce] = useState([]);
  const { form } = Form.useForm()
  useEffect(() => {
    GetVehicle();
  }, []);
  const GetVehicle = async () => {
    const response = await getVehiclelist();
    if (response) {
      setVehicle(response);
      setfilterVihilce(response)
    }
  }


  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const getYearsArray = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };
  const currentYear = new Date().getFullYear();
  const years = getYearsArray(2023, currentYear);

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = [].map((e, index) => [
      index + 1,
      e.client_name,
      e.contact_no,
      e.pan_no,
      e.gst_no,
      e.Address,
      e.moisture_per
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Party Name', 'Contact No.', 'Gst No', 'Pan No', 'Address', 'Moisture %'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'DeliveryOrder');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'PartyList.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterVihilce.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase())
    
    );

    setVehicle(filteredData);


  };

  return (
    <div >
      <Form
        form={form}
        onValuesChange={handleValuesChange}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
         
            <Form.Item name='vehicle_no'>
              <Input
                placeholder="Search Vehicle No"
              />

            </Form.Item>
          </Col>

          <Col>
            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                 
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
        <table id="my-table" className="table-container" ref={componentRef}  >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Fitness</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">RoadTax</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">GreenTax</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Insurance</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">All India Permit</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">State Permit</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">PUC</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Fast Tag</th>
              
            </tr>
          </thead>
          <tbody>
          {vehicle.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_fitness_doc ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_road_tax_doc ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_green_tax_doc ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_insurance_doc ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_all_india_permit ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_state_permit_doc ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_puc_doc ? <MdOutlineDone />: <RxCross2/>}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_fast_tag_doc ? <MdOutlineDone />: <RxCross2/>}</td>
               
              </tr>
            ))}
    
          </tbody>
        </table>
      </div>
    </div>
  )
}
