import React, { useState, useEffect, useMemo } from 'react';
import { getWallet } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Modal, Image } from 'antd';
import { FaFileExcel, FaDownload } from "react-icons/fa";
import AddNewWallet from './AddNewWallet';
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { saveAs } from 'file-saver';
import { CiMail } from "react-icons/ci";
export default function Wallet() {
  const [wallet, setwallet] = useState([]);
  const [filterwallet, setfilterwallet] = useState([]);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {
    GetWallet();
  }, []);

  const GetWallet = async () => {
    const response = await getWallet();
    if (response) {
      setwallet(response);
      setfilterwallet(response)
    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterwallet.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.refill_amount.toLowerCase().includes((allValues.refill_amount == undefined ? '' : allValues.refill_amount).toLowerCase())
    );
    setwallet(filteredData);
  };


  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = wallet.map((e, index) => [
      index + 1,
      e.refill_amount,
      e.refill_type,
      e.current_balance,
      convertDatetimeFormat(e.created_at),
      e.vehicle_no,
    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Amount', 'Type', 'Current Balance', 'Date/Time','Vehicle No'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },

    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Wallte List');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Wallet List.xlsx');
  };


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  const convertDatetimeFormat = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString('en-US');
    return `${formattedDate} ${formattedTime}`;
  }
  return (
    <div >
    
      <Form
        form={form}
        onValuesChange={handleValuesChange}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            {/* <label><b>Party Name</b></label><br /> */}
            <Form.Item name='vehicle_no'>
              <Input
                placeholder='Search by Vehicle No'
              />
            </Form.Item>
          </Col>

          <Col>
            {/* <label><b>Pan Number</b></label><br /> */}
            <Form.Item name='refill_amount'>
              <Input
                placeholder='Search by Amount'

              />
            </Form.Item>
          </Col>
          <Col>
            <AddNewWallet setwallet={setwallet} setfilterwallet={setfilterwallet} />
            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Amount</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Transaction Type</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Current Balance</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Date/Time</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Vehicle No</th>
            </tr>
          </thead>
          <tbody>
            {wallet.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.refill_amount}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.refill_type}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.current_balance}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{convertDatetimeFormat(e.created_at)}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
              </tr>
            ))}

          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
