const baseURL = "https://api.myvehicles.in/"
// const baseURL = " http://192.168.43.174:8000/"
// const baseURL = " http://127.0.0.1:8000/"
//const baseURL = "/api"

const config = {

    apiBaseURL : `${baseURL}`,
    staticBaseURL : `${baseURL}`,
    apiTimeout: 500000
}
export default config;