import React from 'react'

export default function Footer() {
  return (
    <h6
    style={{
backgroundColor:"#1B4F72",
color:"white",
textAlign:"center",
fontFamily:'sans-serif',
fontSize:"12px"
    }}
    >Powered By YashviTech IT Solution</h6>
  )
}
