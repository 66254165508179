import React, { useState ,useEffect} from 'react';
import { Button, Form, Input, Modal, Spin,message,Row,Col,Select} from "antd";
import { updateExpense, getVehiclelist,getTripByVehicleId } from '../../Services/Services';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
const EditExpense = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState([]);
    const [tripData, settripData] = useState([]);
    const [form] = Form.useForm();
    if (props.expenseDetails){
        form.setFieldsValue(props.expenseDetails)
    }
    const getTripData = async (vehicle_id) => {
        const response = await getTripByVehicleId(vehicle_id);
        if (response) {
            form.setFieldsValue(
                {
                    trip_id:response.trip_id
                }
            )
            settripData(response);
        }
    }
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        GetVehicle();
      }, []);
    
      const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
          const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
          setVehicle(sortedArray);
    
        }
      }
    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values,{'com_id':localStorage.getItem('com_id')})
        const response = await updateExpense(props.expenseDetails.expense_id,values);
        setLoading(false);
        if (response){
            message.success('Expense Update Successful');
            const sortedArray = [...response].sort((a, b) => b.expense_id - a.expense_id);
            const filterData =sortedArray.filter(o=>o.expense_type==='Expense')
            props.setexpense(filterData);
            props.setfilterexpense(filterData)
            setIsModalOpen(false);
            form.resetFields();
        } else {
            message.warning('Expense Update Not Successful');
        }
    };
  
    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                   
                    <Spin />
                    <h2>Party Details Updating......</h2>
                </div>
            </Modal>
            <Button onClick={showModal}
                style={{
                //   margin:"10px",
                    backgroundColor: '#1B4F72',
                    color:'white',
                    fontWeight:'600'
                }}
            >
        <EditOutlined style={{ fontSize: '20px', color: 'white',padding:"3px" }} />
            </Button>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            >
                   <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >

                    <Form.Item
                        name="vehicle_id"
                        rules={[
                            {
                                required: true,
                                message: "Select Vehicle No!",
                            },
                        ]}
                        label={<b>Select Vehicle No</b>}
                        style={{ padding: '0px', margin: "1px" }}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                        onChange={getTripData}
                        >
                            {vehicle.map((e) => (
                                <Select.Option value={e.vehicle_id}>
                                    {e.vehicle_no}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* <Form.Item
                        name="trip_id"
                        label={<b>Select Trip No</b>}
                        style={{ padding: '0px', margin: "1px" }}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"

                        >
                            {[tripData].map((e) => (
                                <Select.Option value={e.trip_id}>
                                    {e.from_city}-{e.to_city}
                                </Select.Option>
                            ))}
                           
                        </Select>
                    </Form.Item> */}
                    <Form.Item
                        name="amount"
                        label={<b>Amount</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Amount!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Amount" />
                    </Form.Item>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="check_no"
                                label={<b>Check No</b>}
                                style={{ padding: "1px", margin: "1px" }}

                            >
                                <Input placeholder="Enter Check No" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="check_date"
                                label={<b>  Check Date</b>}
                                style={{ padding: "1px", margin: "1px" }}

                            >
                                <Input type='date' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="descriptions"
                        label={<b>Descriptions</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                       <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default EditExpense;
