import React, { useState, useMemo } from 'react';
import { Button, Form, Input, Modal, Spin, message, Tooltip } from "antd";
import { postWallet, } from '../../Services/Services';
import { FaPlusSquare } from "react-icons/fa";

const AddNewWallet = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values, { com_id: localStorage.getItem('com_id') })
        const response = await postWallet(values);
        setLoading(false);
        if (response) {
            message.success('Wallet Creation Successful');
            setIsModalOpen(false);
            props.setwallet(response);
            props.setfilterwallet(response)
            form.resetFields();
        } else {
            message.warning('Wallet Creation Not Successful');
        }
    };



    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Wallet Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add New Wallet'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            // width={1000}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"

                >

                    <Form.Item
                        name="amount"
                        label={<b>Amount</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Amount!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Amount" />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddNewWallet;
