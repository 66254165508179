import React, { useState, useEffect, useMemo } from 'react';
import { getRepair, deleteRepair, completeTask, profileDetails, getRepairByProfileId,getAllExpense } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Popconfirm, Select } from 'antd';
import AddNewRepair from './AddNewRepair';
import EditRepair from './EditRepair';
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { DeleteOutlined } from '@ant-design/icons';
import { MdFileDownloadDone } from "react-icons/md";
import PrintComplateRepair from './PrintComplateRepair';
export default function Repair() {
  const [repair, setrepair] = useState([]);
  const [filterRepair, setfilterRepair] = useState([]);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const [profile, setProfile] = useState([])
  const [expenseList, setexpenseList] = useState([]);
  const conponentPDF = useRef();
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {
    if (localStorage.getItem('user_type') === 'Company') {
      GetRepair();
    } else {
      ProfileDetails()
    }
  }, []);
  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      setProfile(response)
    }
  }
  const GetRepair = async () => {
    const response = await getRepair();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.repair_id - a.repair_id);
      setrepair(sortedArray);
      setfilterRepair(sortedArray)
    }
  }
  const GetRepairByProfileId = async (id) => {
    const response = await getRepairByProfileId(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.repair_id - a.repair_id);
      setrepair(sortedArray);
      setfilterRepair(sortedArray)
    }
  }
  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterRepair.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.service_center_name.toLowerCase().includes((allValues.service_center_name === undefined ? '' : allValues.service_center_name).toLowerCase())
    );
    setrepair(filteredData);



  };


  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = repair.map((e, index) => [
      index + 1,
      e.vehicle_no,
      e.service_center_name,
      e.contact_no,
      e.address,
      e.start_date,
      e.complete_date,
    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No', 'Service Center Name.', 'Contact No', 'Address', 'Start Date', 'Complate Date'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Modal No.
      { wch: 20 },  // Chasis No
      { wch: 10 },  // GPS
      { wch: 15 },  // Vehicle Status
      { wch: 10 }   // No Of Tire
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Repair List');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Repair List.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const DeleteRepair = async (id) => {
    const response = await deleteRepair(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.repair_id - a.repair_id);
      setrepair(sortedArray);
      setfilterRepair(sortedArray)

    }
  }
  const CompleteRepair = async (repair_id, vehicle_id) => {
    const data = {
      repair_id: repair_id,
      type: 'repair',
      vehicle_id: vehicle_id
    }
    const response = await completeTask(data);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.repair_id - a.repair_id);
      setrepair(sortedArray);
      setfilterRepair(sortedArray)

    }
  }
  const [repaireDetails, setrepaireDetails] = useState({})
  const findRepair = (repair_id) => {
    setrepaireDetails(repair.find(o => o.repair_id === repair_id))
  }
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: `${"Complete Service"}`,
  });
  const GetRepaire = async (repair_id) => {
    const response = await getAllExpense({ repair_id: repair_id });
    if (response) {
      setrepaireDetails(repair.find(o => o.repair_id === repair_id))
      setexpenseList(response);
      generatePDF()
    }

  }
  return (
    <div >
        <div className="print_components" ref={conponentPDF} >
        <PrintComplateRepair expenseList={expenseList} repaireDetails={repaireDetails}/>
      </div>
      {localStorage.getItem('user_type') === 'Company'
        ?
        <Form
          form={form}
          onValuesChange={handleValuesChange}
        >
          <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
            <Col>
              {/* <label><b>Party Name</b></label><br /> */}
              <Form.Item name='vehicle_no'>
                <Input
                  placeholder='Search by Vehicle No'
                />
              </Form.Item>
            </Col>

            <Col>
              {/* <label><b>Pan Number</b></label><br /> */}
              <Form.Item name='service_center_name'>
                <Input
                  placeholder='Search by Service Center Name'

                />
              </Form.Item>
            </Col>
            <Col>
              <AddNewRepair setrepair={setrepair} setfilterRepair={setfilterRepair} />
              <Tooltip
                placement="top"
                title={'Print'}
                arrow={mergedArrow}
              >
                <Button
                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                  }}
                  onClick={handlePrint}
                >
                  <IoIosPrint />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={'Excel'}
                arrow={mergedArrow}
              >
                <Button
                  style={{

                    // margin: "10px",
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: '600',


                  }}
                  onClick={handleExportToExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </Col>
          </div>
        </Form>
        :
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            <Select style={{ width: '200px' }}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={GetRepairByProfileId}
            >
              {profile.map((i) => (
                <Select.Option value={i.id}>{i.name}</Select.Option>
              ))}

            </Select>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
          <br />
        </div>
      }
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              {localStorage.getItem('user_type') === 'Admin' &&
                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Repair Id</th>
              }
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Service Center Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Contact No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Address</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Start Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">End Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Action</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Complete</th>
            </tr>
          </thead>
          <tbody>
            {repair.map((e, index) => (
              <tr style={{ backgroundColor: e.is_complete ? '#b8ebb8' : '' }}>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                {localStorage.getItem('user_type') === 'Admin' &&
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.repair_id}</td>
                }
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.service_center_name}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.contact_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.address}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.start_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.complete_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  {e.is_complete ? '-' : <>

                    <button onClick={() => findRepair(e.repair_id)}>
                      <EditRepair setrepair={setrepair} setfilterRepair={setfilterRepair} repaireDetails={repaireDetails} />
                    </button>
                    <Popconfirm
                      title="Delete Repair"
                      description="Are you sure to delete this Repair?"
                      okText={<span style={{ color: "black" }}>Yes</span>}
                      cancelText="No"
                      onConfirm={() => {
                        DeleteRepair(e.repair_id);
                      }}
                      onCancel={() => {
                        // Handle cancellation if needed
                      }}
                    >
                      <button>
                        <DeleteOutlined
                          style={{
                            backgroundColor: '#1B4F72',
                            color: 'white',
                            fontWeight: '800',
                            padding: "8px",
                            fontSize: "15px",
                            borderRadius: "5px"
                          }}
                        />
                      </button>
                    </Popconfirm>
                  </>
                  }
                </td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  {e.is_complete ? 
                  
                  <button

                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '800',
                    padding: "8px",
                    fontSize: "15px",
                    borderRadius: "5px"
                  }}
                  onClick={()=>GetRepaire(e.repair_id)}
                >
                  <IoIosPrint />
                </button>
                  : <Popconfirm
                    title="Complete Vehicle Repair"
                    description="Are You Sure To Complete Vehicle Repair?"
                    okText={<span style={{ color: "black" }}>Yes</span>}
                    cancelText="No"
                    onConfirm={() => {
                      CompleteRepair(e.repair_id, e.vehicle_id);
                    }}
                    onCancel={() => {
                      // Handle cancellation if needed
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={'Complete Vehicle Repair'}
                      arrow={mergedArrow}
                    >
                      <button

                        style={{
                          backgroundColor: '#1B4F72',
                          color: 'white',
                          fontWeight: '800',
                          padding: "8px",
                          fontSize: "15px",
                          borderRadius: "5px"
                        }}
                      >
                        <MdFileDownloadDone />
                      </button>
                    </Tooltip>
                  </Popconfirm>
                  }
                </td>
              </tr>

            ))}
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>

      </div>
    </div>
  )
}
