import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin, message, Row, Col, Select } from "antd";
import { updateRepair, getVehiclelist} from '../../Services/Services';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
const EditRepair = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState([]);
    const [driverList, setdriverList] = useState([]);
    const [form] = Form.useForm();
    if (props.repaireDetails) {
        form.setFieldsValue(props.repaireDetails)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // useEffect(() => {
    //     GetVehicle();
    // }, []);

    const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
            const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
            setVehicle(sortedArray);

        }
    }
   const onFinish = async (values) => {
        setLoading(true);
        delete values['vehicle_no'];
        Object.assign(values, { 'com_id': localStorage.getItem('com_id') })
        const response = await updateRepair(props.repaireDetails.repair_id, values);
        setLoading(false);
        if (response) {
            message.success('Repair Update Successful');
            const sortedArray = [...response].sort((a, b) => b.repair_id - a.repair_id);
            props.setrepair(sortedArray);
            props.setfilterRepair(sortedArray)
            setIsModalOpen(false);
            form.resetFields();
        } else {
            message.warning('Repair Update Not Successful');
        }
    };
    const handlePhoneKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const isNumeric = charCode >= 48 && charCode <= 57; // check if the key pressed is a number
        const isBackspace = charCode === 8; // check if the key pressed is the backspace key
        const phone = e.target.value.replace(/\D/g, ''); // remove all non-numeric characters
        const isValid = phone.length === 10 || isBackspace; // check if the input is a 10-digit number or the backspace key
        if (!isNumeric && !isBackspace) {
            e.preventDefault(); // prevent the input of non-numeric characters
        }

        if (isValid) {
            e.target.value = phone; // update the input value with the valid phone number
        }
    };
    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >

                    <Spin />
                    <h2>Party Details Updating......</h2>
                </div>
            </Modal>
            <Button onClick={showModal}
                style={{
                    //   margin:"10px",
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                }}
            >
                <EditOutlined style={{ fontSize: '20px', color: 'white', padding: "3px" }} />
            </Button>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >

                    <Form.Item
                        name="vehicle_no"
                        rules={[
                            {
                                required: true,
                                message: "Select Vehicle No!",
                            },
                        ]}
                        label={<b> Vehicle No</b>}
                        style={{ padding: '0px', margin: "1px" }}
                    >
                        {/* <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"

                        >
                            {vehicle.map((e) => (
                                <Select.Option value={e.vehicle_id}>
                                    {e.vehicle_no}
                                </Select.Option>
                            ))}
                        </Select> */}
                        <Input disabled/>
                    </Form.Item>


                    <Form.Item
                        name="service_center_name"
                        label={<b>Service Center Name</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Service Center Name!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Service Center Name" />
                    </Form.Item>

                    <Form.Item
                        name="contact_no"
                        label={<b>Contact No</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Contact No!",
                            },
                        ]}
                    >
                        <Input placeholder='Please Enter Contact No' />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label={<b>Address</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default EditRepair;
