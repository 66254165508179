import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button, Spin, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { completeTask } from '../../Services/Services';
const TripComplete = ({ isModalVisible, setIsModalVisible, settrip, setfilterTrip, tripDetail }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values,
            {
                com_id: localStorage.getItem('com_id'),
                trip_id: tripDetail.trip_id,
                type: 'trip',
                vehicle_id: tripDetail.vehicle_id
            })
        const response = await completeTask(values);
        setLoading(false);
        if (response) {
            message.success('Trip Complete Successful');
            setIsModalVisible(false);
            const sortedArray = [...response].sort((a, b) => b.trip_id - a.trip_id);
            settrip(sortedArray);
            setfilterTrip(sortedArray)
            form.resetFields();

        } else {
            message.warning('Trip Complete Not Successful');
        }

    };


    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={200}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Trip Complete......</h2>
                </div>
            </Modal>

            <Modal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >

                    <Form.Item
                        name="bill_no"
                        label={<b>Bill No</b>}
                        style={{ padding: "1px", margin: "1px" }}

                    >
                        <Input placeholder="Enter Bill No" />
                    </Form.Item>
                    <Form.Item
                        name="freight_amount"
                        label={<b>Freight Amount</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Freight Amount!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Freight Amount" />
                    </Form.Item>
                    <Form.Item
                        name="picture"
                        label={<label className='font-bold' style={{ fontSize: "14px" }}>Freight Picture</label>}
                        getValueFromEvent={({ file }) => file.originFileObj}

                    >
                        <Upload

                            accept="image/*"  >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="descriptions"
                        label={<b>Descriptions</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default TripComplete;
