import React, { useState, useEffect, useMemo } from 'react';
import { getRoadTax, profileDetails, getRoadTaxByVehicleId } from '../../../Services/Services';
import { Col, Input, Button, Modal, Tooltip, Form, Image, Select } from 'antd';
import AddRoadTax from './AddRoadTax';
import { FaFileExcel, FaDownload } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import config from '../../../config';
import { IoEyeSharp } from "react-icons/io5";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { CiMail } from "react-icons/ci";
export default function RoadTax() {
  const [roadTax, setroadTax] = useState([]);
  const [filterRoadTax, setfilterRoadTax] = useState([]);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  useEffect(() => {
    if (localStorage.getItem('user_type') === 'Company') {
      GetRoadTax();
    } else {
      ProfileDetails()
    }
  }, []);

  const [profile, setProfile] = useState([])

  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      setProfile(response)
    }
  }


  const GetRoadTax = async () => {
    const response = await getRoadTax();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.roadtax_doc_id - a.roadtax_doc_id);
      setroadTax(sortedArray);
      setfilterRoadTax(sortedArray)
    }
  }

  const GetRoadTaxDoc = async (com_id) => {
    const response = await getRoadTaxByVehicleId(com_id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.roadtax_doc_id - a.roadtax_doc_id);
      setroadTax(sortedArray);
      setfilterRoadTax(sortedArray)
    }
  }
  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterRoadTax.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.road_tax_no.toLowerCase().includes((allValues.road_tax_no === undefined ? '' : allValues.road_tax_no).toLowerCase())
    );
    setroadTax(filteredData);


  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = roadTax.map((e, index) => [
      index + 1,
      e.vehicle_no,
      e.road_tax_no,
      e.road_tax_amount,
      e.valid_from,
      e.expiry_date

    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No', 'RoadTax No.', 'RoadTax Amount', 'From Date', 'To Date'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },

    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'RoadTax');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'RoadTax Documents.xlsx');
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false)
  };
  const [docPath, setdocpath] = useState('')
  const handleDocuments = (path) => {
    setOpen(true)
    setdocpath(path)
  }

  const shareByEmail = (imageUrl) => {
    const subject = 'Check out this image!';
    const body = 'Hey, I thought you might like this image!';
    const dataUrl = imageUrl;

    const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(dataUrl)}`;

    window.location.href = mailToLink;
  };
  return (
    <div >
      <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        width={380}
      >
        <Image src={docPath} width={300} alt='img' />
        <div style={{ textAlign: "center" }}>
          <Tooltip
            placement="top"
            title={'Download'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => saveAs(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px",
                marginRight: "20px"
              }}
            >
              <FaDownload

              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={'Mail Image'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => shareByEmail(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px"
              }}
            >
              <CiMail />
            </button>
          </Tooltip>
        </div>
      </Modal>
      {localStorage.getItem('user_type') === 'Company'
        ?
        <Form
          form={form}
          onValuesChange={handleValuesChange}
        >
          <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
            <Col>
              {/* <label><b>Party Name</b></label><br /> */}
              <Form.Item name='vehicle_no'>
                <Input
                  placeholder='Search by Vehicle No'
                />
              </Form.Item>
            </Col>

            <Col>
              {/* <label><b>Pan Number</b></label><br /> */}
              <Form.Item name='road_tax_no'>
                <Input
                  placeholder='Search by RoadTax Number'

                />
              </Form.Item>
            </Col>
            <Col>
              <AddRoadTax setroadTax={setroadTax} setfilterRoadTax={setfilterRoadTax} />
              <Tooltip
                placement="top"
                title={'Print'}
                arrow={mergedArrow}
              >
                <Button
                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                  }}
                  onClick={handlePrint}
                >
                  <IoIosPrint />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={'Excel'}
                arrow={mergedArrow}
              >
                <Button
                  style={{

                    // margin: "10px",
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: '600',


                  }}
                  onClick={handleExportToExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </Col>
          </div>
        </Form>
        :
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            <Select style={{ width: '200px' }}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={GetRoadTaxDoc}
            >
              {profile.map((i) => (
                <Select.Option value={i.id}>{i.name}</Select.Option>
              ))}

            </Select>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
          <br />
        </div>
      }
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className="print-container">
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              {localStorage.getItem('user_type') === 'Admin' &&
                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">ID</th>

              }
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">RoadTax No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">RoadTax Amount</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Valid From</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Valid To</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Picture</th>

            </tr>
          </thead>
          <tbody>
            {roadTax.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                {localStorage.getItem('user_type') === 'Admin' &&
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.roadtax_doc_id}</td>
                }
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.road_tax_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.road_tax_amount}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.valid_from}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.expiry_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  <Tooltip
                    placement="top"
                    title={'View Documents'}
                    arrow={mergedArrow}
                  >
                    <button
                      onClick={() => handleDocuments(e.picture)}
                      style={{
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"
                      }}
                    >
                      <IoEyeSharp

                      />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
