import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaHome, FaUser, FaRegUser, FaIndent, FaMoneyBill } from "react-icons/fa";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { FaTruckArrowRight } from "react-icons/fa6";
import { IoDocumentsSharp } from "react-icons/io5";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { ImTruck } from "react-icons/im";
import { removeToken } from '../../Services/LocalStorageService';
import { TbReport } from "react-icons/tb";
import { BsTruckFront } from "react-icons/bs";
import { LogoutOutlined, RedoOutlined } from '@ant-design/icons';
import { BsFillWalletFill } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
const companyRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome style={{ fontSize: "20px" }} />,
  },

  {
    path: "/vehicle",
    name: "Vehicles",
    icon: <ImTruck style={{ fontSize: "20px" }} />,
  },
  {
    path: "/driver",
    name: "Driver",
    icon: <FaUser style={{ fontSize: "20px" }} />,
  },
  {
    name: "Documents",
    icon: <IoDocumentsSharp style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/fitness",
        name: "Fitness",
      },
      {
        path: "/road-tax",
        name: "Road Taxt",
      },
      {
        path: "/insurance",
        name: "Insurance",
      },
      {
        path: "/fast-tag",
        name: "FastTag",
      },
      {
        path: "/state-permit",
        name: "State Pemit",
      },
      {
        path: "/all-india-permit",
        name: "All India Pemit",
      },
      {
        path: "/puc-doc",
        name: "PUC",
      },
      {
        path: "/green-tax",
        name: "Green Tax",
      },
    ],
  },
  {
    name: "Trip",
    icon: <FaTruckArrowRight style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/add-new-trip",
        name: "New Trip",
      },
      {
        path: "/trip-expense",
        name: "Expense",
      },
      // {
      //   path: "/trip-income",
      //   name: "Income",
      // },
    ],
  },
  
  {
    name: "Repair",
    icon: <BsTruckFront style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/repair",
        name: "New Repair",
      },
      {
        path: "/repair-expense",
        name: "Expense",
      },
    ],
  },
  {
    path: "/expense",
    name: "Expense",
    icon: <GiReceiveMoney style={{ fontSize: "25px" }} />,
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: <BsFillWalletFill style={{ fontSize: "22px" }} />,
  },
  
  {
    name: "Reports",
    icon: <TbReport style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/trip-expense-rpt",
        name: "Trip Expense",
      },
      {
        path: "/service-expense-rpt",
        name: "Service Expense",
      },
      {
        path: "/monthly-expense-rpt",
        name: "Monthly Expense",
      },
      {
        path: "/yearly-expense-rpt",
        name: "Yearly Expense",
      },
 
   
      {
        path: "/trip-list-rpt",
        name: "Trip List",
      },
         {
        path: "/tire-status",
        name: "Tire Status",
      },
      // {
      //   path: "/monthly-trip-rpt",
      //   name: "Monthly Trip",
      // },
      // {
      //   path: "/yearly-trip-rpt",
      //   name: "Yearly Trip",
      // },
    
    ],

  },

];
const AdminRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome style={{ fontSize: "20px" }} />,
  },
  {
    path: "/company-list",
    name: "Company",
    icon: <FaUser style={{ fontSize: "20px" }} />,
  },
  {
    path: "/vehicle",
    name: "Vehicles",
    icon: <ImTruck style={{ fontSize: "20px" }} />,
  },
  {
    path: "/driver",
    name: "Driver",
    icon: <FaUser style={{ fontSize: "20px" }} />,
  },
  {
    name: "Documents",
    icon: <IoDocumentsSharp style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/fitness",
        name: "Fitness",
      },
      {
        path: "/road-tax",
        name: "Road Taxt",
      },
      {
        path: "/insurance",
        name: "Insurance",
      },
      {
        path: "/fast-tag",
        name: "FastTag",
      },
      {
        path: "/state-permit",
        name: "State Pemit",
      },
      {
        path: "/all-india-permit",
        name: "All India Pemit",
      },
      {
        path: "/puc-doc",
        name: "PUC",
      },
      {
        path: "/green-tax",
        name: "Green Tax",
      },
    ],
  },
  {
    name: "Trip",
    icon: <FaTruckArrowRight style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/add-new-trip",
        name: "New Trip",
      },
      {
        path: "/trip-expense",
        name: "Expense",
      },
      // {
      //   path: "/trip-income",
      //   name: "Income",
      // },
    ],
  },
  {
    path:  "/repair",
    name: "Repair",
    icon: <BsTruckFront style={{ fontSize: "20px" }} />,
  },
  {
    path: "/expense",
    name: "Expense",
    icon: <GiReceiveMoney style={{ fontSize: "25px" }} />,
  },
  {
    name: "Reports",
    icon: <TbReport style={{ fontSize: "20px" }} />,
    subRoutes: [
      {
        path: "/trip-expense-rpt",
        name: "Trip Expense",
      },
      {
        path: "/service-expense-rpt",
        name: "Service Expense",
      },
      {
        path: "/monthly-expense-rpt",
        name: "Monthly Expense",
      },
      {
        path: "/yearly-expense-rpt",
        name: "Yearly Expense",
      },
 
      {
        path: "/documents-availability",
        name: "Document Status",
      },
      {
        path: "/trip-list-rpt",
        name: "Trip List",
      },
      // {
      //   path: "/monthly-trip-rpt",
      //   name: "Monthly Trip",
      // },
      // {
      //   path: "/yearly-trip-rpt",
      //   name: "Yearly Trip",
      // },
    
    ],

  },

];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate()
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  const handleLogout = () => {
    removeToken()
    navigate('/')
  }
  return (
    <>
      <div className="main-container" >
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          {/* <div className="top_section">


            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div> */}
          <br />

          <section className="routes">
            {(localStorage.getItem('user_type') === 'Company' ? companyRoutes : AdminRoutes).map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>

                </NavLink>
              );
            })}
              <div className="icon" onClick={handleLogout}><LogoutOutlined style={{fontSize:"18px" ,margin:"10px"}} />Logout</div>
      
          </section>

        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
