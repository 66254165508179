import "./App.css";
import { useEffect } from "react";
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Vehicles from "./pages/VehicleMaster/Vehicles";
import Landing from './components/LandingPage/Landing'
import Fitness from "./pages/Documents/FitnessDocuments/Fitness";
import RoadTax from "./pages/Documents/RoadTaxDocuments/RoadTax";
import GreenTax from "./pages/Documents/GreenTaxDocuments/GreenTax";
import Insurance from "./pages/Documents/InsuranceDocuments/Insurance";
import Puc from "./pages/Documents/PucDocuments/Puc";
import StatePermit from "./pages/Documents/StatePermitDocuments/StatePermit";
import AllIndiaPermit from "./pages/Documents/AllIndiaPermitDocuments/AllIndiaPermit";
import FastTagDocuments from "./pages/Documents/FastTagDocuments/FastTagDocuments";
import Trip from "./pages/TripMaster/Trip";
import TripExpense from "./pages/TripMaster/TripExpense";
import Driver from "./pages/Driver/Driver";
import Repair from "./pages/Repair/Repair";
import RepaireExpense from "./pages/Repair/RepaireExpense"
import TripExpenseRpt from "./pages/Reports/TripExpenseRpt";
import ServiceExpenseRpt from './pages/Reports/ServiceExpenseRpt'
import MonthlyExpenseRpt from "./pages/Reports/MonthlyExpenseRpt";
import YearlyExpenseRpt from './pages/Reports/YearlyExpenseRpt'
import DocumentsStatus from "./pages/Reports/DocumentsStatus";
import TripList from "./pages/Reports/TripList";
import VehicleDocument from "./pages/Reports/VehicleDocument";
import CompanyList from "./pages/CompanyList/CompanyList";
import VehicleExpense from "./pages/VehicleExpense/VehicleExpense";
import VehicleTireStatus from "./pages/Reports/VehicleTireStatus";
import Wallet from "./pages/Wallet/Wallet";
const AuthenticatedRoutes = () => {
  const isAuthenticated = localStorage.getItem('access');
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect to home page if the token is not present
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      {isAuthenticated ?
        <>
          <SideBar >
            <Routes>
              <Route path="/" element={isAuthenticated ? <Dashboard /> : <Login />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/driver" element={<Driver />} />
              <Route path="/vehicle" element={<Vehicles />} />
              <Route path="/fitness" element={<Fitness />} />
              <Route path="/road-tax" element={<RoadTax />} />
              <Route path="/green-tax" element={<GreenTax />} />
              <Route path="/insurance" element={<Insurance />} />
              <Route path="/state-permit" element={<StatePermit />} />
              <Route path="/all-india-permit" element={<AllIndiaPermit />} />
              <Route path="/fast-tag" element={<FastTagDocuments />} />
              <Route path="/puc-doc" element={<Puc />} />
              <Route path="/add-new-trip" element={<Trip />} />
              <Route path="/trip-expense" element={<TripExpense />} />
              <Route exact path="/repair" element={<Repair />}></Route>
              <Route exact path="/repair-expense" element={<RepaireExpense />}></Route>
              <Route exact path="/trip-expense-rpt" element={<TripExpenseRpt />}></Route>
              <Route exact path="/service-expense-rpt" element={<ServiceExpenseRpt />}></Route>
              <Route exact path="/monthly-expense-rpt" element={<MonthlyExpenseRpt />}></Route>
              <Route exact path="/yearly-expense-rpt" element={<YearlyExpenseRpt />}></Route>
              <Route exact path="/document-status" element={<DocumentsStatus />}></Route>
              <Route exact path="/trip-list-rpt" element={<TripList />}></Route>
              <Route exact path="/documents-availability" element={<VehicleDocument />}></Route>
              <Route exact path="/company-list" element={<CompanyList />}></Route>
              <Route exact path="/expense" element={<VehicleExpense />}></Route>
              <Route exact path="/tire-status" element={<VehicleTireStatus />}></Route>
              <Route exact path="/wallet" element={<Wallet />}></Route>
             
              <Route path="*" element={<> not found</>} />
            </Routes>
          </SideBar>
        </>
        : <>
          <Routes>
            <Route path="/" element={<Landing />} />
            {/* <Route path="/login" element={<Login />} /> */}

          </Routes>
        </>}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthenticatedRoutes />
    </BrowserRouter>
  );
}

export default App;



