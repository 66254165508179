import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin, message, Row, Col, Select, Tooltip, Upload, Radio } from "antd";
import { postExpense, getVehiclelist, getTripByVehicleId } from '../../Services/Services';
import { FaPlusSquare } from "react-icons/fa";
import TextArea from 'antd/es/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
const AddNewExpense = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');
    const [vehicle, setVehicle] = useState([]);
    const [tripData, settripData] = useState([]);
    const [isFule,setFule]=useState(false)
    console.log(isFule,'fuke')
    useEffect(() => {
        GetVehicle();
    }, []);

    const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
            const filterVehicle = response.filter(o => o.vehicle_status === 'on_trip')
            setVehicle(filterVehicle);
        }
    }
    const getTripData = async (vehicle_id) => {
        const response = await getTripByVehicleId(vehicle_id);
        if (response) {
            form.setFieldsValue(
                {
                    trip_id: response.trip_id
                }
            )
            settripData(response);
        }
    }
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values, { com_id: localStorage.getItem('com_id'), expense_type: 'Trip' })
        const response = await postExpense(values);
        setLoading(false);
        if (response) {
            message.success('Expense Creation Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.expense_id - a.expense_id);
            const filterData = sortedArray.filter(o => o.expense_type === 'Trip')
            props.setexpense(filterData);
            props.setfilterexpense(filterData)
            form.resetFields();
            GetVehicle();
        } else {
            message.warning('Expense Creation Not Successful');
        }
    };

    const handleValuesChange = (changedValues, allValues) => {
        if(allValues.is_fuel){
        const amount=(parseFloat(allValues.Quantity)*parseFloat(allValues.rate)).toFixed(2)
        form.setFieldsValue({amount:amount})
        }
      };

    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Expense Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add New Expense'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            // width={1000}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                >

                    <Form.Item
                        name="vehicle_id"
                        rules={[
                            {
                                required: true,
                                message: "Select Vehicle No!",
                            },
                        ]}
                        label={<b>Select Vehicle No</b>}
                        style={{ padding: '0px', margin: "1px" }}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            onChange={getTripData}
                        >
                            {vehicle.map((e) => (
                                <Select.Option value={e.vehicle_id}>
                                    {e.vehicle_no}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="trip_id"
                        label={<b>Select Trip No</b>}
                        style={{ padding: '0px', margin: "1px" }}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            disabled
                        >
                            {[tripData].map((e) => (
                                <Select.Option value={e.trip_id}>
                                    {e.from_city}-{e.to_city}
                                </Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="is_fuel"
                        label={<b>Select For Fule</b>}
                        style={{ padding: '0px', margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Select!",
                            },
                        ]}
                    
                    >
                        <Radio.Group>
                            <Radio value={true} onChange={()=>setFule(true)}>Yes</Radio>
                            <Radio value={false} onChange={()=>setFule(false)}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {isFule &&
                      <Row>
                      <Col span={12}>
                          <Form.Item
                              name="Quantity"
                              label={<b>Quantity (Ltr)</b>}
                              style={{ padding: "1px", margin: "1px" }}
                              rules={[
                                  {
                                      required: true,
                                      message: "Please Quantity!",
                                  },
                              ]}
                          >
                              <Input placeholder="Enter Quantity" type='number'/>
                          </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item
                              name="rate"
                              label={<b>Rate Per Ltr</b>}
                              style={{ padding: "1px", margin: "1px" }}
                              rules={[
                                  {
                                      required: true,
                                      message: "Please Rate Per Ltr!",
                                  },
                              ]}
                          >
                              <Input placeholder="Enter Rate Per Ltr" type='number'/>
                          </Form.Item>
                      </Col>
                     
                  </Row>
                    
                    }
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label={<b>Amount</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Amount!",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Amount" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item
                                name="picture"
                                label={<label className='font-bold' style={{ fontSize: "14px" }}> Picture</label>}
                                getValueFromEvent={({ file }) => file.originFileObj}

                            >
                                <Upload accept="image/*"  >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="check_no"
                                label={<b>Check No</b>}
                                style={{ padding: "1px", margin: "1px" }}

                            >
                                <Input placeholder="Enter Check No" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="check_date"
                                label={<b>  Check Date</b>}
                                style={{ padding: "1px", margin: "1px" }}

                            >
                                <Input type='date' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="descriptions"
                        label={<b>Descriptions</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddNewExpense;
