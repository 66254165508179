import React, { useState ,useEffect} from 'react';
import { Button, Form, Input, Modal, Spin,message,Row,Col,Select} from "antd";
import { updateTrip ,getVehiclelist,getDriver} from '../../Services/Services';
import { EditOutlined } from '@ant-design/icons';
const EditTrip = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState([]);
    const [driverList, setdriverList] = useState([]);
    const [form] = Form.useForm();
    if (props.tripDetails){
        form.setFieldsValue(props.tripDetails)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        GetVehicle();
        GetDriver()
    }, []);
    
      const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
          const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
          setVehicle(sortedArray);
    
        }
      }
 
    const GetDriver = async () => {
        const response = await getDriver();
        if (response) {
            setdriverList(response)
        }
      }

    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values,{'com_id':localStorage.getItem('com_id')})
        const response = await updateTrip(props.tripDetails.trip_id,values);
        setLoading(false);
        if (response){
            message.success('Trip Update Successful');
            const sortedArray = [...response].sort((a, b) => b.trip_id - a.trip_id);
            props.settrip(sortedArray);
            props.setfilterTrip(sortedArray)
            setIsModalOpen(false);
            form.resetFields();
        } else {
            message.warning('Trip Update Not Successful');
        }
    };
    const handlePhoneKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const isNumeric = charCode >= 48 && charCode <= 57; // check if the key pressed is a number
        const isBackspace = charCode === 8; // check if the key pressed is the backspace key
        const phone = e.target.value.replace(/\D/g, ''); // remove all non-numeric characters
        const isValid = phone.length === 10 || isBackspace; // check if the input is a 10-digit number or the backspace key
        if (!isNumeric && !isBackspace) {
          e.preventDefault(); // prevent the input of non-numeric characters
        }
    
        if (isValid) {
          e.target.value = phone; // update the input value with the valid phone number
        }
      };
    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                   
                    <Spin />
                    <h2>Party Details Updating......</h2>
                </div>
            </Modal>
            <Button onClick={showModal}
                style={{
                //   margin:"10px",
                    backgroundColor: '#1B4F72',
                    color:'white',
                    fontWeight:'600'
                }}
            >
        <EditOutlined style={{ fontSize: '20px', color: 'white',padding:"3px" }} />
            </Button>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            >
              <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="vehicle_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select Vehicle No!",
                                    },
                                ]}
                                label={<b>Select Vehicle No</b>}
                                style={{ padding: '0px', margin: "1px" }}
                            >
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    disabled

                                >
                                    {vehicle.map((e) => (
                                        <Select.Option value={e.vehicle_id}>
                                            {e.vehicle_no}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="driver_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select Driver Name!",
                                    },
                                ]}
                                label={<b>Select Driver Name</b>}
                                style={{ padding: '0px', margin: "1px" }}
                            >
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"

                                >
                                    {driverList.map((e) => (
                                        <Select.Option value={e.driver_id}>
                                            {e.driver_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>

                            <Form.Item
                                name="from_city"
                                label={<b>From City</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter From City!",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter From City" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="to_city"
                                label={<b>To City</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter To City!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter To City' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>

                        <Col span={12}>
                            <Form.Item
                                name="consignee_name"
                                label={<b>Consignee Name</b>}
                                style={{ padding: "1px", margin: "1px" }}
                            >
                                <Input placeholder='Enter Consignee Name' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="consignee_contact"
                                label={<b>Consignee Contact</b>}
                                style={{ padding: "1px", margin: "1px" }}
                            >
                                <Input placeholder='Enter Consignee Contact No' maxLength={10} minLength={10} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default EditTrip;
