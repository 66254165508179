import React,{useState,useEffect} from 'react'
import { PieChart } from '@mui/x-charts'
export default function VehicleStatusChart({ vehicle }) {
const [width, setWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(window.innerWidth > 768 ? 300 : 300);
console.log(width,value)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setValue(window.innerWidth > 768 ? 500 : 300);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const stopVehicleCount = vehicle.filter(o => o.vehicle_status === 'stop').length;
    const onTripVehicleCount = vehicle.filter(o => o.vehicle_status === 'on_trip').length;
    const repairVehicleCount = vehicle.filter(o => o.vehicle_status === 'repair').length;
    return (
        <>
            <PieChart
                series={[
                    {
                        data: [
                            { id: 0, value: stopVehicleCount, label: 'Stop' },
                            { id: 1, value: onTripVehicleCount, label: 'On Trip' },
                            { id: 2, value: repairVehicleCount, label: 'Repair' },
                        ],
                    },
                ]}
                width={value}
                height={200}
            />
            <h1 style={{ textAlign: "center",marginTop:'20px' }}>Vehicle Status Chart</h1>
        </>
    )
}
