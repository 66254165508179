import React, { useEffect, useState } from 'react';
import './Webintro.css'
import img from './image/bg.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Login from '../../pages/Auth/Login';
import Registration from '../../pages/Auth/Registration';
import { Link } from "react-scroll";
function Webintro() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            easing: 'ease-in-out', // Animation easing
        });
    }, []);
    const [contentIndex, setContentIndex] = useState(0);
    const contents = [
        'Vehicle Management',
        'Vehicle Status',
        'Trip Details',
        'Vehicle Documents',
        'Cost Management',
        'Yearly & Monthly Maintains Report',
    ];
    const [currentContent, setCurrentContent] = useState('');

    useEffect(() => {
        let currentText = '';
        let textIndex = 0;

        const interval = setInterval(() => {
            if (textIndex < contents[contentIndex].length) {
                currentText += contents[contentIndex][textIndex];
                setCurrentContent(currentText);
                textIndex++;
            } else {
                // Typing effect finished, wait for a moment and start deleting
                clearInterval(interval);
                setTimeout(() => {
                    deleteText();
                }, 1500); // Adjust the pause before deletion as needed
            }
        }, 100); // Adjust the typing speed as needed

        return () => clearInterval(interval);
    }, [contentIndex]);

    const deleteText = () => {
        let textIndex = currentContent.length;

        const interval = setInterval(() => {
            if (textIndex > 0) {
                setCurrentContent((prevContent) => prevContent.slice(0, -1));
                textIndex--;
            } else {
                // Deletion finished, move to the next content
                clearInterval(interval);
                setContentIndex((prevIndex) => (prevIndex + 1) % contents.length);
            }
        }, 50); // Adjust the deletion speed as needed
    };
    return (
        <div id='home'>
            <div className='web_intro_container' data-aos="zoom-out-up">
                <img src={img} className='web_intro_img'></img>
                   <div className='web_intro'>
                    <h1 id='web_heading' data-aos="flip-right">Easy & Effective
                        Software to Optimize Your Bussiness</h1>
                    <h1 className='web_animate' data-aos="flip-right">{currentContent}</h1>
                    <br />
                </div>
                <div className='justify-around m-auto flex sm:flex-row flex-col  sm:items-start items-center mt-5 w-[30%]  absolute sm:top-[60%] top-[80%] left-[50%] -translate-x-1/2 -translate-y-1/2'>
                    <Login />
                    <Registration/>
                </div>
                {/* <div className='justify-around m-auto flex sm:flex-row flex-col  sm:items-start items-center mt-5 w-[30%]  absolute sm:top-[60%] top-[80%] left-[50%] -translate-x-1/2 -translate-y-1/2'>
                    <Login />
                    <Registration/>
                </div> */}
            </div>
        </div>
    )
}

export default Webintro
