import React, { useState, useEffect, useMemo } from 'react';
import { profileDetails } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Popconfirm, Modal, Image } from 'antd';
import { FaFileExcel, FaDownload } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
export default function CompanyList() {
  const [profile, setprofile] = useState([]);
  const [filterprofile, setfilterprofile] = useState([]);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {
    ProfileDetails();
  }, []);

  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.id - a.id);
      setprofile(sortedArray);
      setfilterprofile(sortedArray)
    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterprofile.filter(item =>
      item.mobile_no.toLowerCase().includes((allValues.mobile_no === undefined ? '' : allValues.mobile_no).toLowerCase()) &&
      item.name.toLowerCase().includes((allValues.name === undefined ? '' : allValues.name).toLowerCase())
    );

    setprofile(filteredData);


  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = profile.map((e, index) => [
      index + 1,
      e.client_name,
      e.contact_no,
      e.pan_no,
      e.gst_no,
      e.Address,
      e.moisture_per
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Party Name', 'Contact No.', 'Gst No', 'Pan No', 'Address', 'Moisture %'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'DeliveryOrder');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'PartyList.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const convertDatetimeFormat = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString('en-US');
    return `${formattedDate} ${formattedTime}`;
  }
  return (
    <div >
     
      <Form
        form={form}
        onValuesChange={handleValuesChange}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">


          <Col>
            {/* <label><b>Pan Number</b></label><br /> */}
            <Form.Item name='name'>
              <Input
                placeholder='Search by Company Name'

              />
            </Form.Item>
          </Col>
          <Col>
            {/* <label><b>Party Name</b></label><br /> */}
            <Form.Item name='mobile_no'>
              <Input
                placeholder='Search by Contact No'
              />
            </Form.Item>
          </Col>
          <Col>
            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <table id="my-table" className="table-container" ref={componentRef}  >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Com Id</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Company Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Contact No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Email</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Regisraion Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Status</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Current Balance</th>
              
            </tr>
          </thead>
          <tbody>
            {profile.map((e, index) => (
              <tr style={{ backgroundColor: e.is_active ? '' : 'red' }} >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.id}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.name}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.mobile_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.email}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{convertDatetimeFormat(e.created_at)}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.is_active?'Activate':'Block'}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.current_bal}</td>
           
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
