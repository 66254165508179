import React from 'react'
import { Row, Col } from 'antd'
export default function PrintComplateRepair({ expenseList, repaireDetails }) {
    const convertDatetimeFormat = (datetime) => {
        const date = new Date(datetime);
        const formattedDate = date.toLocaleDateString('en-GB');
        const formattedTime = date.toLocaleTimeString('en-US');
        return `${formattedDate} ${formattedTime}`;
    }
    return (
        <div style={{ margin: "5px" }}>
            <Row style={{ border: 'solid 0.1px black', padding: '10px' }}>
                <Col span={12} >
                  
                    <h2> Service Center Name : {repaireDetails['service_center_name']}</h2>
                    <h2> Contact No : {repaireDetails['contact_no']}</h2>
                    <h2> Address : {repaireDetails['address']}</h2>
                </Col>
                <Col span={12}>
                <h2> Vehicle No : {repaireDetails['vehicle_no']}</h2>
                    <h2> Start Date : {repaireDetails['start_date']}</h2>
                    <h2> End Date :{repaireDetails['complete_date']} </h2>
                </Col>
            </Row>
            <table id="my-table" className="table-container"   >

                <thead className="w-full text-white">
                    <tr>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Amount</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Check No</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Check Date</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Vender Name</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Descriptions</th>
                        <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Date/Time</th>

                    </tr>
                </thead>
                <tbody>

                    {expenseList.map((e, index) => (
                        <tr >
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.amount}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.check_no}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.check_date}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vender_name}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.descriptions}</td>
                            <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{convertDatetimeFormat(e.created_at)}</td>

                        </tr>
                    ))}
                    <tr style={{ backgroundColor: '#e9e9e9' }}>
                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" colSpan={2}><b>Total</b></td>
                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                            <b>{expenseList.reduce(
                                (accumulator, currentValue) =>
                                    accumulator + (+currentValue.amount),
                                0
                            ).toFixed(3)}</b></td>
                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" colSpan={5} ></td>
                    </tr>
                </tbody>
            </table>
            <div className="print-footer">
                Powered By Yashvitech IT Solution Pvt. Ltd.
            </div>
        </div>

    )
}
