import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin, message, Row, Col, Select, Tooltip } from "antd";
import { postRepair, getVehiclelist } from '../../Services/Services';
import { FaPlusSquare } from "react-icons/fa";
import TextArea from 'antd/es/input/TextArea';
const AddNewRepair = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');
    const [vehicle, setVehicle] = useState([]);
    useEffect(() => {
        GetVehicle();
    }, []);

    const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
            const filterVehicle = response.filter(o => o.vehicle_status === 'stop')
            setVehicle(filterVehicle);
        }
    }
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values, { com_id: localStorage.getItem('com_id') })
        const response = await postRepair(values);
        setLoading(false);
        if (response) {
            message.success('Repair Creation Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.repair_id - a.repair_id);
            props.setrepair(sortedArray);
            props.setfilterRepair(sortedArray)
            form.resetFields();
            GetVehicle();
        } else {
            message.warning('Repair Creation Not Successful');
        }
    };


    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Repair Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add New Repair'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            // width={1000}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >

                    <Form.Item
                        name="vehicle_id"
                        rules={[
                            {
                                required: true,
                                message: "Select Vehicle No!",
                            },
                        ]}
                        label={<b>Select Vehicle No</b>}
                        style={{ padding: '0px', margin: "1px" }}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"

                        >
                            {vehicle.map((e) => (
                                <Select.Option value={e.vehicle_id}>
                                    {e.vehicle_no}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        name="service_center_name"
                        label={<b>Service Center Name</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Service Center Name!",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Service Center Name" />
                    </Form.Item>

                    <Form.Item
                        name="contact_no"
                        label={<b>Contact No</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Contact No!",
                            },
                        ]}
                    >
                        <Input placeholder='Please Enter Contact No' />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label={<b>Address</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddNewRepair;
