import React, { useState, useEffect } from 'react'
import { Row, Col, Card } from 'antd'
import { UserOutlined, UserAddOutlined, TabletOutlined, CheckCircleOutlined, FileDoneOutlined, QuestionCircleOutlined, FileUnknownOutlined, ArrowUpOutlined, CheckSquareOutlined, SkypeOutlined, SelectOutlined } from '@ant-design/icons';
import { countDetails } from '../../Services/Services'
import { Link } from 'react-router-dom';
import { TfiTruck } from "react-icons/tfi";
import { FaRupeeSign } from "react-icons/fa";
import { MdTaskAlt } from "react-icons/md";
import { HiOutlineLockOpen } from "react-icons/hi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import TripCharts from './TripCharts';
import DocStatusTable from './DocStatusTable';
import DocumentsStatus from './DocumentsStatus';
import CalendarChart from './CalendarChart';
import VehicleStatusChart from './VehicleStatusChart';
import './Dashboard.css'
export default function Dashboard() {
  const [count, setCount] = useState({})
  useEffect(() => {
    CountDetails();
  }, []);
  const CountDetails = async () => {
    const response = await countDetails();
    if (response) {
      setCount(response)
    }

  }
  return (
    <div style={{ maxHeight: "90vh", overflowY: "auto" }}>

<Card className='chart_card'>
        {localStorage.getItem('user_type') === 'Company'
          ?

          <>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >

              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",

                  // alignContent: 'center' // Remove this line
                }}>
                  <TfiTruck style={{ fontSize: "50px" }} /><br />
                  Vehicles <br /> {count.vehicle}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <UserOutlined style={{ fontSize: "50px" }} /><br />
                  Driver <br /> {count.driver}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <MdTaskAlt style={{ fontSize: "50px" }} /><br />
                  Complete Trip <br /> {count.complete_trip}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <HiOutlineLockOpen style={{ fontSize: "50px" }} /><br />
                  On Trip <br /> {count.on_trip}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <HiOutlineClipboardDocumentList style={{ fontSize: "50px" }} /><br />
                  Total Freight <br /> {count.total_expense_amount}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <FaRupeeSign style={{ fontSize: "50px" }} /><br />
                  Fule Balance  <br /> {count.fule_balance}
                </div>

              </Col>
            </Row>

          </>
          :
          <>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >

              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",

                  // alignContent: 'center' // Remove this line
                }}>
                  <TfiTruck style={{ fontSize: "50px" }} /><br />
                  Vehicles <br /> {count.vehicle}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <UserOutlined style={{ fontSize: "50px" }} /><br />
                  Driver <br /> {count.driver}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <MdTaskAlt style={{ fontSize: "50px" }} /><br />
                  Complete Trip <br /> {count.complete_trip}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <HiOutlineLockOpen style={{ fontSize: "50px" }} /><br />
                  On Trip <br /> {count.on_trip}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <HiOutlineClipboardDocumentList style={{ fontSize: "50px" }} /><br />
                  Total Freight <br /> {count.total_freight_amount}
                </div>

              </Col>
              <Col className='dashboard_card' >

                <div style={{
                  display: 'flex', // Add display flex
                  flexDirection: 'column', // Stack items vertically
                  justifyContent: 'center', // Center items vertically
                  alignItems: 'center', // Center items horizontally
                  background: "white",
                  padding: '8px 0',
                  textAlign: "center",
                  color: "#1B4F72",
                  borderRadius: "5%",
                  // alignContent: 'center' // Remove this line
                }}>
                  <FaRupeeSign style={{ fontSize: "50px" }} /><br />
                  Total Expenses  <br /> {count.total_expense_amount}
                </div>

              </Col>
            </Row>

          </>
        }
      </Card>
      
      <Card className='chart_card'>

        <Row>
          <Col >
            {count.documents && <VehicleStatusChart vehicle={count.documents} />}
          </Col>
          <Col  className='trip_chart'>
            {count.monthly_chart && <TripCharts chart_data={count.monthly_chart} />}
          </Col>
        </Row>
   
      </Card>
   
      <Card className='chart_card'>
        {count.documents && <DocumentsStatus documents={count.documents} />}

      </Card>

    </div>
  )
}




















