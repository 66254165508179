import React, { useState, useRef } from 'react'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Card } from 'antd';
import emailjs from '@emailjs/browser';
import { Modal, Button } from "antd";
import MapComponent from './MapComponent ';
import './Pages.css'
export default function Contact() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('')
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_10bivt8', 'template_ndtsf1z', form.current, 'x1CSpE8Vmw0elYLmk')
            .then((result) => {
                if (result.text === 'OK') {
                    setIsModalOpen(true)
                    form.current.reset();
                }
            }, (error) => {
                console.log(error.text);
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div id='contact' data-aos="fade-up" className='contact_class'>
            <Modal
                open={isModalOpen}
                footer={null}
                closable={false}
                width={400}
                style={{ textAlign: "center" }}
            >
                <h1 >Thank You {name} From MyVehicle Team</h1>
                <h1 >We Will Contact You Very Soon</h1>
                <Button onClick={handleCancel}
                    style={{
                        marginTop: "10px",
                        color: "white",
                        backgroundColor: "#6A5ACD"
                    }}
                >Ok</Button>

            </Modal>
            <div className='m-auto text-[30px] font-semibold my-3' style={{ textAlign: "center" }}>Contact To Our Team</div>

            <div className='flex sm:flex-row flex-col'>

                <div className='sm:w-[50%] w-[100%] '>
                    <div className="max-w-md mx-auto mt-8 text-start">
                        <form ref={form} onSubmit={sendEmail} className="bg-[#eaeaea] shadow-md rounded px-8 pt-6 pb-8 mb-4">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 'border-red-500' : ''
                                        }`}
                                    id="name"
                                    type="text"
                                    placeholder="Name"
                                    name="user_name"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ boxShadow: 'inset 0 2px 5px rgba(0,0,0,.08)' }}
                                />
                                {/* {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>} */}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                                    Company Name
                                </label>
                                <input
                                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="company"
                                    type="text"
                                    placeholder="Company Name"
                                    name="subject"
                                    style={{ boxShadow: 'inset 0 2px 5px rgba(0,0,0,.08)' }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
                                    Mobile
                                </label>
                                <input
                                    className={` border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  'border-red-500' : ''
                                        }`}
                                    id="mobile"
                                    type="text"
                                    placeholder="Mobile"
                                    name="mobileNumber"
                                    required
                                    style={{ boxShadow: 'inset 0 2px 5px rgba(0,0,0,.08)' }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
                                    Email
                                </label>
                                <input
                                    className={` border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  'border-red-500' : ''
                                        }`}
                                    type="email"
                                    name="user_email"
                                    placeholder="Email address"
                                    required
                                    style={{ boxShadow: 'inset 0 2px 5px rgba(0,0,0,.08)' }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                    Description
                                </label>
                                <textarea
                                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="description"
                                    placeholder="Description"

                                    name="message"
                                    required
                                    style={{ boxShadow: 'inset 0 2px 5px rgba(0,0,0,.08)' }}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-slate-900 hover:bg-zinc-100 border border-2 hover:border-black border-black hover:text-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className='sm:w-[50%] w-[100%]'>
            
                    <div className="max-w-md mx-auto mt-8 text-start">
                <h2 style={{ textAlign: "center" }}><b>OFFICE</b></h2>
                <br />
                <h3 ><FaMapMarkerAlt style={{ marginLeft: "200px" }} /></h3>
                <h3> 1st Floor, Front of Union Bank ,Lily Chowk,Puranibasti Raipur (C.G),</h3>
                <h3> info@yashvitech.com, 8223079476, 8319308575</h3>
                <div>
                    <div style={{ display: "flex", paddingTop: "30px", color: "white" }}>
                        <Card style={{ marginRight: "10px", backgroundColor: '#1B4F72', }} className='social_icons'>
                            <a
                                href="https://www.facebook.com/profile.php?id=100094370628556"
                                target="_blank"

                            >
                                <FaFacebook className='social_icons_size'  />
                            </a>
                        </Card>
                        <Card style={{ marginRight: "10px", backgroundColor: '#1B4F72', }} className='social_icons'>
                            <a
                                href="https://www.instagram.com/yashvitechraipur/"
                                target="_blank"
                            >
                                <FaInstagramSquare className='social_icons_size'  />
                            </a>
                        </Card>
                        <Card style={{ marginRight: "10px", backgroundColor: '#1B4F72', }} className='social_icons'>
                            <a
                                href="https://www.linkedin.com/in/yashvitech-it-solution-b10b1a282/"
                                target="_blank"

                            >
                                <FaLinkedin className='social_icons_size'  />
                            </a>
                        </Card>
                        {/* <Card style={{ marginRight: "10px", backgroundColor: '#1B4F72', }} className='social_icons'>
                            <FaTwitter className='social_icons_size' />
                        </Card> */}
                        <Card style={{ marginRight: "10px", backgroundColor: '#1B4F72', }} className='social_icons'>
                            <a
                                href="https://youtube.com/@YashvitechITSolution?si=iRydMOEileM4odLQ"
                                target="_blank"

                            >
                                <FaYoutube className='social_icons_size' />
                            </a></Card>
                    </div>
                    <br />
                </div>
            </div>
                </div>


            </div>
       
        </div>
    )
}
