import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin, message, Row, Col, Select, Tooltip, Upload } from "antd";
import { postExpense, getVehiclelist, getRepairByVehicleId } from '../../Services/Services';
import { FaPlusSquare } from "react-icons/fa";
import TextArea from 'antd/es/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
const AddNewExpense = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');
    const [vehicle, setVehicle] = useState([]);
    const [repairData, setrepairData] = useState([]);
    useEffect(() => {
        GetVehicle();
    }, []);

    const GetVehicle = async () => {
        const response = await getVehiclelist();
        if (response) {
            const filterVehicle = response.filter(o => o.vehicle_status === 'repair')
            setVehicle(filterVehicle);
        }
    }
    const getRepairDetails = async (vehicle_id) => {
        const response = await getRepairByVehicleId(vehicle_id);
        if (response) {
            form.setFieldsValue(
                {
                    repair_id: response.repair_id
                }
            )
            setrepairData(response);
        }
    }
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values, { com_id: localStorage.getItem('com_id'), expense_type: 'Repair' })
        const response = await postExpense(values);
        setLoading(false);
        if (response) {
            message.success('Expense Creation Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.expense_id - a.expense_id);
            const filterData = sortedArray.filter(o => o.expense_type === 'Repair')
            props.setexpense(filterData);
            props.setfilterexpense(filterData)
            form.resetFields();
            GetVehicle();
        } else {
            message.warning('Expense Creation Not Successful');
        }
    };



    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Expense Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add New Expense'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
            // width={1000}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="vehicle_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select Vehicle No!",
                                    },
                                ]}
                                label={<b>Select Vehicle No</b>}
                                style={{ padding: '0px', margin: "1px" }}
                            >
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    onChange={getRepairDetails}
                                >
                                    {vehicle.map((e) => (
                                        <Select.Option value={e.vehicle_id}>
                                            {e.vehicle_no}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>  <Form.Item
                            name="repair_id"
                            label={<b>Select repair No</b>}
                            style={{ padding: '0px', margin: "1px" }}
                        >
                            <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                disabled
                            >
                                {[repairData].map((e) => (
                                    <Select.Option value={e.repair_id}>
                                        {e.service_center_name}
                                    </Select.Option>
                                ))}

                            </Select>
                        </Form.Item></Col>
                    </Row>


                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label={<b>Amount</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Amount!",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Amount" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="vender_name"
                                label={<b>Vender Name</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Vender Name!",
                                    },
                                ]}
                            >
                                <Input placeholder="Please Enter Vender Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="check_no"
                                label={<b>Check No</b>}
                                style={{ padding: "1px", margin: "1px" }}

                            >
                                <Input placeholder="Enter Check No" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="check_date"
                                label={<b>  Check Date</b>}
                                style={{ padding: "1px", margin: "1px" }}

                            >
                                <Input type='date' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="picture"
                        label={<label className='font-bold' style={{ fontSize: "14px" }}> Picture</label>}
                        getValueFromEvent={({ file }) => file.originFileObj}

                    >
                        <Upload  accept="image/*"  >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="descriptions"
                        label={<b>Descriptions</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddNewExpense;
