import React, { useState } from 'react';
import { Button, Form, Input, Modal, Spin,message,Row,Col,Upload} from "antd";
import { updateDriver} from '../../Services/Services';
import { EditOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
const EditDriver = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    if (props.driverDetails){
        form.setFieldsValue(props.driverDetails)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
 
    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values,{'com_id':localStorage.getItem('com_id')})
        const response = await updateDriver(props.driverDetails.driver_id,values);
        setLoading(false);
        if (response){
            message.success('Driver Update Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.driver_id - a.driver_id);
            props.setdriver(sortedArray);
            props.setdriverDetails(sortedArray)
              form.resetFields();
          
        } else {
            message.warning('Driver Update Not Successful');
        }
    };
    const handlePhoneKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const isNumeric = charCode >= 48 && charCode <= 57; // check if the key pressed is a number
        const isBackspace = charCode === 8; // check if the key pressed is the backspace key
        const phone = e.target.value.replace(/\D/g, ''); // remove all non-numeric characters
        const isValid = phone.length === 10 || isBackspace; // check if the input is a 10-digit number or the backspace key
        if (!isNumeric && !isBackspace) {
          e.preventDefault(); // prevent the input of non-numeric characters
        }
    
        if (isValid) {
          e.target.value = phone; // update the input value with the valid phone number
        }
      };
    
    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                   
                    <Spin />
                    <h2>Driver Details Updating......</h2>
                </div>
            </Modal>
            <Button onClick={showModal}
                style={{
                //   margin:"10px",
                    backgroundColor: '#1B4F72',
                    color:'white',
                    fontWeight:'600'
                }}
            >
        <EditOutlined style={{ fontSize: '20px', color: 'white',padding:"3px" }} />
            </Button>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
                className="custom-modal-close" 
            >
                    <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="driver_name"
                                label={<b>Driver Name</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Driver Name!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Driver Name!' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="contact_no"
                                label={<b>Driver Contact No</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Contact No!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Contact No!' maxLength={10} minLength={10} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="license_no"
                        label={<b>License No</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Select License No!",
                            },
                        ]}
                    >
                        <Input placeholder='Enter License No' />
                    </Form.Item>
                    {/* <Row>
                        <Col span={12}>
                            <Form.Item
                                name="license"
                                label={<label className='font-bold' style={{ fontSize: "14px" }}>Pic License</label>}
                                getValueFromEvent={({ file }) => file.originFileObj}

                            >
                                <Upload beforeUpload={checkFileType} >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="aadhar_card"
                                label={<label className='font-bold' style={{ fontSize: "14px" }}>Upload Aadhar Card</label>}
                                getValueFromEvent={({ file }) => file.originFileObj}

                            >
                                <Upload beforeUpload={checkFileType} >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Form.Item
                        name="address"
                        label={<b>Address</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default EditDriver;
