import React, { useState, useEffect, useMemo } from 'react';
import "./tableStyle.css"
import { getVehiclelist, deleteVehicle, profileDetails, getVehiclelistbyProfileId } from '../../Services/Services';
import { Col, Input, Button, Popconfirm, Form, Tooltip, Radio, Select } from 'antd';
import AddNewVehicle from './AddNewVehicle';
import EditClients from './EditClients'
import { DeleteOutlined } from '@ant-design/icons';
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import VehicleTire from './VehicleTire'

export default function Vehicles() {
  const [vehicle, setVehicle] = useState([]);
  const [filterVihilce, setfilterVihilce] = useState([]);
  const [profile, setProfile] = useState([])
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {
    if (localStorage.getItem('user_type') === 'Company') {
      GetVehicle();
    } else {
      ProfileDetails()
    }
  }, []);

  const GetVehicle = async () => {
    const response = await getVehiclelist();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
      setVehicle(sortedArray);
      setfilterVihilce(sortedArray)
    }
  }
  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      setProfile(response)
    }
  }
  const GetVehicleByPofileId = async (id) => {
    const response = await getVehiclelistbyProfileId(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
      setVehicle(sortedArray);
      setfilterVihilce(sortedArray)
    }
  }
  const DeleteVehicle = async (id) => {
    const response = await deleteVehicle(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
      setVehicle(sortedArray);
      setfilterVihilce(sortedArray)

    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterVihilce.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.model_no.toLowerCase().includes((allValues.model_no === undefined ? '' : allValues.model_no).toLowerCase()) &&
      item.vehicle_status.toLowerCase().includes((allValues.vehicle_status === undefined ? '' : allValues.vehicle_status).toLowerCase())

    );

    setVehicle(filteredData);


  };


  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = vehicle.map((e, index) => [
      index + 1,
      e.vehicle_no,
      e.model_no,
      e.chasis_no,
      e.gps_avalibility ? 'Yes' : 'No',
      e.vehicle_status === 'stop' ? 'Stop' : e.vehicle_status === 'on_trip' ? 'On Trip' : 'Repair',
      e.no_of_tire,
    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No', 'Modal No.', 'Chasis No', 'GPS', 'Vehicle Status', 'No Of Tire'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Modal No.
      { wch: 20 },  // Chasis No
      { wch: 10 },  // GPS
      { wch: 15 },  // Vehicle Status
      { wch: 10 }   // No Of Tire
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'VehicleList');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Vehicle List.xlsx');
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [vehicleData, setvehicleData] = useState([])
  const [vehicleId , setVehicleId] =useState('')
  const findVihicle = (id) => {
    const tire_list = vehicle.find(o => o.vehicle_id === id)
    setvehicleData(tire_list.tire_details)
    setVehicleId(tire_list.vehicle_id)
  }





  return (
    <div >
      {localStorage.getItem('user_type') === 'Company'
        ?
        <Form
          form={form}
          onValuesChange={handleValuesChange}
        >
          <div className=" grid grid-cols-1 sm:grid sm:grid-cols-4 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
            <Col>
              {/* <label><b>Party Name</b></label><br /> */}
              <Form.Item name='vehicle_no'>
                <Input
                  placeholder='Search by Vehicle No'
                />
              </Form.Item>
            </Col>

            <Col>
              {/* <label><b>Pan Number</b></label><br /> */}
              <Form.Item name='model_no'>
                <Input
                  placeholder='Search by Model Number'

                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="vehicle_status"
              >
                <Radio.Group>
                  <Radio value={'stop'}>Stop</Radio>
                  <Radio value={'on_trip'}>On Trip</Radio>
                  <Radio value={'repair'}>Repair</Radio>
                  {/* <Radio value={null}>All</Radio> */}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col>
              <AddNewVehicle setVehicle={setVehicle} setfilterVihilce={setfilterVihilce} />

              <Tooltip
                placement="top"
                title={'Print'}
                arrow={mergedArrow}
              >
                <Button
                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                  }}
                  onClick={handlePrint}
                >
                  <IoIosPrint />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={'Excel'}
                arrow={mergedArrow}
              >
                <Button
                  style={{

                    // margin: "10px",
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: '600',


                  }}
                  onClick={handleExportToExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </Col>
          </div>
        </Form>
        :

        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            <Select style={{ width: '200px' }}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={GetVehicleByPofileId}
            >
              {profile.map((i) => (
                <Select.Option value={i.id}>{i.name}</Select.Option>
              ))}

            </Select>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
          <br />
        </div>

      }

      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className="print-container">
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              {localStorage.getItem('user_type') === 'Admin' &&
                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Vehicle Id</th>
              }
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Model No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Chasis No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Gps</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Status</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">No Of Tire</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base" id='col_visible'>Tires No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base" id='col_visible'>Action</th>
            
            </tr>
          </thead>
          <tbody>
            {vehicle.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                {localStorage.getItem('user_type') === 'Admin' &&
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_id}</td>

                }
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.model_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.chasis_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.gps_avalibility ? 'Yes' : 'No'}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                  {e.vehicle_status === 'stop' ? 'Stop' : e.vehicle_status === 'on_trip' ? 'On Trip' : 'Repair'}
                </td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.no_of_tire}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  <button onClick={() => findVihicle(e.vehicle_id)}>
                    <VehicleTire vehicleData={vehicleData} vehicleId={vehicleId}/>
                  </button>
                </td>
             
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                    <Popconfirm
                      title="Delete Party"
                      description="Are you sure to delete this Vehicle?"
                      okText={<span style={{ color: "black" }}>Yes</span>}
                      cancelText="No"
                      onConfirm={() => {
                        DeleteVehicle(e.vehicle_id);
                      }}
                      onCancel={() => {
                        // Handle cancellation if needed
                      }}
                    >
                      <button>
                        <DeleteOutlined
                          style={{
                            backgroundColor: '#1B4F72',
                            color: 'white',
                            fontWeight: '800',
                            padding: "8px",
                            fontSize: "15px",
                            borderRadius: "5px"
                          }}
                        />
                      </button>
                    </Popconfirm>

                  </td>
                

              </tr>
            ))}
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>      
      </div>

    </div>
  )
}
