import React, { useState, useEffect, useMemo } from 'react';
import { getTrip } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Modal, Image } from 'antd';
import { FaFileExcel, FaDownload } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { IoEyeSharp } from "react-icons/io5";
import config from '../../config';
import { saveAs } from 'file-saver';
import { CiMail } from "react-icons/ci";

export default function TripList() {
  const [trip, settrip] = useState([]);
  const [filterTrip, setfilterTrip] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {
    GetTrip();
  }, []);

  const GetTrip = async () => {
    const response = await getTrip();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.trip_id - a.trip_id);
      settrip(sortedArray);
      setfilterTrip(sortedArray)
    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterTrip.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.driver_name.toLowerCase().includes((allValues.driver_name === undefined ? '' : allValues.driver_name).toLowerCase())
    );

    settrip(filteredData);

  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = trip.map((e, index) => [
      index + 1,
      e.vehicle_no,
      e.from_city,
      e.to_city,
      e.driver_name,
      e.consignee_name,
      e.consignee_contact,
      e.start_date,
      e.complete_date,
      e.bill_no,
      e.freight_amount

    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No','From City','To City','Driver Name','Consignee Name','Consignee Contact','Start Date','End Date','Bill No','Freight Amt'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1','H1','I1','J1','K1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Modal No.
      { wch: 20 },  // Chasis No
      { wch: 10 },  // GPS
      { wch: 15 },  // Vehicle Status
      { wch: 10 } ,  // No Of Tire
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Modal No.
      { wch: 20 },  // Chasis No
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trip List');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Trip List.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false)
  };
  const [docPath, setdocpath] = useState('')
  const handleDocuments = (path) => {
    setOpen(true)
    setdocpath(path)
  }
  const shareByEmail = (imageUrl) => {
    const subject = 'Check out this image!';
    const body = 'Hey, I thought you might like this image!';
    const dataUrl = imageUrl;

    const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(dataUrl)}`;

    window.location.href = mailToLink;
  };

  return (
    <div >
      <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        width={380}
      >
        <Image src={docPath} width={300} alt='img' />
        <div style={{ textAlign: "center" }}>
          <Tooltip
            placement="top"
            title={'Download'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => saveAs(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px",
                marginRight: "20px"
              }}
            >
              <FaDownload

              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={'Mail Image'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => shareByEmail(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px"
              }}
            >
              <CiMail />
            </button>
          </Tooltip>
        </div>
      </Modal>
      <Form
        form={form}
        onValuesChange={handleValuesChange}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            {/* <label><b>Party Name</b></label><br /> */}
            <Form.Item name='vehicle_no'>
              <Input
                placeholder='Search by Vehicle No'
              />
            </Form.Item>
          </Col>

          <Col>
            {/* <label><b>Pan Number</b></label><br /> */}
            <Form.Item name='driver_name'>
              <Input
                placeholder='Search by Driver Name'

              />
            </Form.Item>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }}
        ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">From City</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">To City</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Driver Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Consignee Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Consignee Contact</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Start Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">End Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Bill No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Freight Amt</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Freight Picture</th>
            </tr>
          </thead>
          <tbody>
            {trip.map((e, index) => (
              <tr style={{ backgroundColor: e.is_complete ? '#b8ebb8' : '' }} >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.from_city}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.to_city}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.driver_name}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.consignee_name}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.consignee_contact}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.start_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.complete_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.bill_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.freight_amount}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  <Tooltip
                    placement="top"
                    title={e.picture === null ? '' : 'View Documents'}
                    arrow={mergedArrow}
                  >
                    <Button
                      onClick={() => handleDocuments(e.picture)}
                      style={{
                        backgroundColor: e.picture === null ? 'white' : '#1B4F72',
                        color: e.picture === null ? 'black' : 'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"

                      }}
                      disabled={e.picture === null ? true : false}
                    >
                      <IoEyeSharp

                      />
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
