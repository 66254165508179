import React, { useState, useEffect, useMemo } from 'react';
import { getVehiclelist, getTireStatusByVehilcleId } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Select, message, Modal, Image } from 'antd';
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import config from '../../config';
import { saveAs } from 'file-saver';
import { CiMail } from "react-icons/ci";
import { FaFileExcel, FaDownload } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
export default function VehicleTireStatus() {
  const [arrow, setArrow] = useState('Show');
  const [vehicle, setVehicle] = useState([]);
  const [tireStatus, settireStatus] = useState([]);
  const { form } = Form.useForm()
  useEffect(() => {
    GetVehicle();
  }, []);
  const GetVehicle = async () => {
    const response = await getVehiclelist();
    if (response) {
      setVehicle(response);
    }
  }
  const GetVehicleTireList = async (vehicle_id) => {
    const response = await getTireStatusByVehilcleId(vehicle_id);
    if (response) {
      settireStatus(response);
    }
  }

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = tireStatus.map((e, index) => [
      index + 1,
      e.tire_no,
      e.capacity,
      convertDatetimeFormat(e.created_at),

    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Tire No', 'Capacity', 'Date',],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Modal No.
      { wch: 20 },  // Chasis No

    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tire Status');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Tire Status.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const convertDatetimeFormat = (datetime) => {
    const date = new Date(datetime);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString('en-US');
    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <div >

      <Form
        form={form}
      >
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            {/* <label><b>Party Name</b></label><br /> */}
            <Form.Item name='vehicle_no'>
              <Select
                showSearch
                placeholder="Select Vehicle No"
                optionFilterProp="children"
                onChange={GetVehicleTireList}
              >
                {vehicle.map((e) => (
                  <Select.Option value={e.vehicle_id}>
                    {e.vehicle_no}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          <Col>
            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
        </div>
      </Form>
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"   >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Tire No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Capacity</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Date</th>

            </tr>
          </thead>
          <tbody>

            {tireStatus.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.tire_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.capacity}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{convertDatetimeFormat(e.created_at)}</td>

              </tr>
            ))}

          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
