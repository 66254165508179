import React, { useState, useEffect, useMemo } from 'react';
import { getExpense, deleteExpense, profileDetails ,getExpenseComId} from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Popconfirm, Modal, Image ,Select} from 'antd';
import { FaFileExcel, FaDownload } from "react-icons/fa";
import AddNewExpense from './AddNewExpense';
import EditExpense from './EditExpense';
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { DeleteOutlined } from '@ant-design/icons';
import { IoEyeSharp } from "react-icons/io5";
import config from '../../config';
import { saveAs } from 'file-saver';
import { CiMail } from "react-icons/ci";
export default function VehicleExpense() {
  const [expense, setexpense] = useState([]);
  console.log(expense,'e')
  const [filterexpense, setfilterexpense] = useState([]);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const [profile, setProfile] = useState([])
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  useEffect(() => {
    if (localStorage.getItem('user_type') === 'Company') {
      GetExpense();
    } else {
      ProfileDetails()
    }
  }, []);
  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      setProfile(response)
    }
  }

  const GetExpense = async () => {
    const response = await getExpense();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.expense_id - a.expense_id);
      const filterData = sortedArray.filter(o => o.expense_type === 'Expense')
      setexpense(filterData);
      setfilterexpense(filterData)
    }
  }
  const GetExpenseByComId = async (com_id) => {
    const response = await getExpenseComId(com_id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.expense_id - a.expense_id);
      const filterData = sortedArray.filter(o => o.expense_type === 'Expense')
      setexpense(filterData);
      setfilterexpense(filterData)
    }
  }

  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterexpense.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.amount.toLowerCase().includes((allValues.amount == undefined ? '' : allValues.amount).toLowerCase())
    );
    setexpense(filteredData);


  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = expense.map((e, index) => [
      index + 1,
      e.vehicle_no,
      e.amount,
      e.check_no,
      e.check_date,
      e.descriptions,

    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No', 'Amount', 'Check No', 'Check Date', 'Descriptions',],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  // Sr. No
      { wch: 20 },  // Vehicle No
      { wch: 20 },  // Amt
      { wch: 20 },  // Des
      { wch: 20 },  // Amt
      { wch: 20 },  // Des

    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Expense');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Vehicle Expense.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const DeleteExpense = async (id) => {
    const response = await deleteExpense(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.expense_id - a.expense_id);
      const filterData = sortedArray.filter(o => o.expense_type === 'Trip')
      setexpense(filterData);
      setfilterexpense(filterData)

    }
  }
  const [expenseDetails, setexpenseDetails] = useState({})
  const findExpnse = (expense_id) => {
    setexpenseDetails(expense.find(o => o.expense_id === expense_id))
  }
  const [docPath, setdocpath] = useState('')
  const handleDocuments = (path) => {
    setOpen(true)
    setdocpath(path)
  }
  const shareByEmail = (imageUrl) => {
    const subject = 'Check out this image!';
    const body = 'Hey, I thought you might like this image!';
    const dataUrl = imageUrl;

    const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(dataUrl)}`;

    window.location.href = mailToLink;
  };
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false)
  };
  return (
    <div >
      <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        width={380}
      >
        <Image src={docPath} width={300} alt='img' />
        <div style={{ textAlign: "center" }}>
          <Tooltip
            placement="top"
            title={'Download'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => saveAs(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px",
                marginRight: "20px"
              }}
            >
              <FaDownload

              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={'Mail Image'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => shareByEmail(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px"
              }}
            >
              <CiMail />
            </button>
          </Tooltip>
        </div>
      </Modal>
      {localStorage.getItem('user_type') === 'Company'
        ?
        <Form
          form={form}
          onValuesChange={handleValuesChange}
        >
          <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
            <Col>
              {/* <label><b>Party Name</b></label><br /> */}
              <Form.Item name='vehicle_no'>
                <Input
                  placeholder='Search by Vehicle No'
                />
              </Form.Item>
            </Col>

            <Col>
              {/* <label><b>Pan Number</b></label><br /> */}
              <Form.Item name='amount'>
                <Input
                  placeholder='Search by Amount'

                />
              </Form.Item>
            </Col>
            <Col>
              <AddNewExpense setexpense={setexpense} setexpenseDetails={setexpenseDetails} />
              <Tooltip
                placement="top"
                title={'Print'}
                arrow={mergedArrow}
              >
                <Button
                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                  }}
                  onClick={handlePrint}
                >
                  <IoIosPrint />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={'Excel'}
                arrow={mergedArrow}
              >
                <Button
                  style={{

                    // margin: "10px",
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: '600',


                  }}
                  onClick={handleExportToExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </Col>
          </div>
        </Form>
        :
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            <Select style={{ width: '200px' }}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={GetExpenseByComId}
            >
              {profile.map((i) => (
                <Select.Option value={i.id}>{i.name}</Select.Option>
              ))}

            </Select>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
          <br />
        </div>
      }
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"  >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              {localStorage.getItem('user_type') === 'Admin' &&
                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">ID</th>
              }
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Amount</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Check No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Check Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Descriptions</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base"
                id='col_visible'>Picture</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base"
                id='col_visible'>Action</th>
            </tr>
          </thead>
          <tbody>
            {expense.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                {localStorage.getItem('user_type') === 'Admin' &&
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.expense_id}</td>
                }
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.amount}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.check_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.check_date}</td>

                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.descriptions}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base"
                  id='col_visible'>
                  <Tooltip
                    placement="top"
                    title={e.picture === null ? '' : 'View Documents'}
                    arrow={mergedArrow}
                  >
                    <Button
                      onClick={() => handleDocuments(e.picture)}
                      style={{
                        backgroundColor: e.picture === null ? 'white' : '#1B4F72',
                        color: e.picture === null ? 'black' : 'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"

                      }}
                      disabled={e.picture === null ? true : false}
                    >
                      <IoEyeSharp

                      />
                    </Button>
                  </Tooltip>
                </td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base"
                  id='col_visible'>
                  <button onClick={() => findExpnse(e.expense_id)}>
                    <EditExpense setexpense={setexpense} setfilterexpense={setfilterexpense} expenseDetails={expenseDetails} />
                  </button>
                  {localStorage.getItem('user_type') === 'Admin' &&
                    <Popconfirm
                      title="Delete Expense"
                      description="Are you sure to delete this Expense?"
                      okText={<span style={{ color: "black" }}>Yes</span>}
                      cancelText="No"
                      onConfirm={() => {
                        DeleteExpense(e.expense_id);
                      }}
                      onCancel={() => {
                        // Handle cancellation if needed
                      }}
                    >
                      <button>
                        <DeleteOutlined
                          style={{
                            backgroundColor: '#1B4F72',
                            color: 'white',
                            fontWeight: '800',
                            padding: "8px",
                            fontSize: "15px",
                            borderRadius: "5px"
                          }}
                        />
                      </button>
                    </Popconfirm>
                  }
                </td>
              </tr>
            ))}
                                <tr style={{ backgroundColor: '#e9e9e9' }}>
                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" colSpan={2}><b>Total</b></td>
                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                            <b>{expense.reduce(
                                (accumulator, currentValue) =>
                                    accumulator + (+currentValue.amount),
                                0
                            ).toFixed(3)}</b></td>
                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" colSpan={5} ></td>
                    </tr>
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>

      </div>
    </div>
  )
}
