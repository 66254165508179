import { message } from 'antd'
import API from './API';


// user registrations api
export const userRegistration = async (fromData) => {
    const response = await API.post(`api/user/register/`, fromData,
        { headers: { 'Content-Type': 'application/json'} }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

// user login api
export const userLogin = async (fromData) => {
    const response = await API.post(`api/user/login/`, fromData,
        { headers: { 'Content-Type': 'application/json' } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const profileDetails = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/user/profile/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}



// vehicle create and get api
export const postVehicle = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/vehicle/`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getVehiclelist = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/vehicle/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getVehiclelistbyProfileId = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/vehicle/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const deleteVehicle = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.delete(`api/vehicle/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const updateVehicle = async (vehilce_id, fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.put(`api/vehicle/${vehilce_id}`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// fitness create and get api
export const postFitness = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/fitness/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getFitness = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/fitness/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getFitnessByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/fitness/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// road tax create and get api
export const postRoadTax = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/road-tax/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getRoadTax = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/road-tax/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getRoadTaxByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/road-tax/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// green tax create and get api
export const postGreenTax = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/green-tax/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getGreenTax = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/green-tax/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getGreenTaxByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/green-tax/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// puc create and get api
export const postPuc = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/puc/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getPuc = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/puc/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getPucByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/puc/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// insurance create and get api
export const postInsurance = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/insurance/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getInsurance = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/insurance/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getInsuranceByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/insurance/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// state permit create and get api
export const postStatePermit = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/state-permit/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getStatePermit = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/state-permit/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getStatePermitByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/state-permit/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// All India Permit create and get api
export const postAllIndiaPermit = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/all-india-permit/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getAllIndiaPermit = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/all-india-permit/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getAllIndiaPermitByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/all-india-permit/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// fitness document
export const postFastTag = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/fast-tag/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getFastTag = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/fast-tag/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getFastTagVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/fast-tag/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

// vehicle trip and get api
export const postTrip = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/trip/`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getTrip = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/trip/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getTripByProfileId = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/trip/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const getTripByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/trip/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


export const deleteTrip = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.delete(`api/trip/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const updateTrip = async (trip_id, fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.put(`api/trip/${trip_id}`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


// vehicle trip expens and get api
export const postExpense = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/expense/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getExpense = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/expense/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getExpenseComId = async (com_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/expense/${com_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const deleteExpense = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.delete(`api/expense/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const updateExpense = async (expense_id, fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.put(`api/expense/${expense_id}`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

// vehicle trip expens and get api
export const postIncome = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/income/`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getIncome = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/income/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const deleteIncome = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.delete(`api/income/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const updateIncome = async (income_id, fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.put(`api/income/${income_id}`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

//  driver expens and get api
export const postDriver = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/user/driver/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getDriver = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/user/driver/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getDriverByProfileId = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/user/driver/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const deleteDriver = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.delete(`api/user/driver/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const updateDriver = async (driver_id, fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.put(`api/user/driver/${driver_id}`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


//  Repair create and get api
export const postRepair = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/repair/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getRepair = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/repair/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getRepairByProfileId = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/repair/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const getRepairByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/repair/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const deleteRepair = async (id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.delete(`api/repair/${id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const updateRepair = async (repair_id, fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.put(`api/repair/${repair_id}`, fromData,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const completeTask = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/complete/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


// get all trip details by trip id
export const getTripAllByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/vehicle/trip/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// get app expense by repair id and trip id
export const getAllExpense = async (data) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/expense/list/`, data,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// get all expense by trip id
export const getAllIncome = async (data) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/income/list/`, data,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

// get all repair details by vehicle id
export const getRepairAllByVehicleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/vehicle/repair/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
// get  expense for monthly repory and yearly report
export const getYearlyMonthlyExpense = async (data) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/expense/monthly/yearly/`, data,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


export const countDetails = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/count-details/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}

export const documents = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/documents/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}
export const getTireStatusByVehilcleId = async (vehicle_id) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/tire-status/${vehicle_id}`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


export const postWallet = async (fromData) => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.post(`api/wallet/`, fromData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}


// get all trip details by trip id
export const getWallet = async () => {
    const access = JSON.parse(localStorage.getItem("access"))
    const response = await API.get(`api/wallet/`,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access}` } }).catch(
            err => message.error('regisration failed.')
        )
    return response ? response.data : {}
}




















































































































































































































































































































































