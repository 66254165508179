import React, { useState } from 'react';
import { Button, Form, Input, Row, Modal, Spin, message, Radio } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { userLogin } from '../../Services/Services'
import { storeToken, storeResponse, removeToken } from '../../Services/LocalStorageService'
import { GoogleLogin } from 'react-google-login';
const Login = ({ props }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const responseGoogle = (response) => {
    console.log(response);
    // Handle the response from Google
  };
  const onFinish = async (values) => {
    setLoading(true)
    const response = await userLogin(values);
    console.log(response)
    setLoading(false)
    if (response.token) {
      removeToken()
      storeToken(response.token)
      storeResponse(response.user)
      navigate('/Dashboard')
    }
    else if (response.activate) {
      message.open({
        type: 'error',
        content: response.activate,
      })
    }

    else {
      message.open({
        type: 'error',
        content: 'Invalid Email Or Password',
      })
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };


  return (<>

    <Modal
      open={loading}
      footer={null}
      // closable={false}
      width={200}
      style={{ textAlign: "center" }}
      
    >
      <h2><b>Login.....</b></h2>
      <Spin />
    </Modal>


    <button
      onClick={showModal}
      // className='p-3 transition-all rounded-lg border border-2 border-[yellow] min-w-[40%] hover:bg-[black] text-[white] font-semibold'
      className='p-3 rounded-lg border border-2 border-[#f5b921] hover:border-[black] min-w-[40%] hover:bg-[white] hover:text-[black] font-semibold text-[white] bg-[black] sm:ml-5 sm:mt-0 mt-2'

    >
      Login
    </button>
    <Modal
      open={isModalOpen}
      footer={null}
      // closable={false}
      onCancel={() => setIsModalOpen(false)}
      width={400}
      className="custom-modal-close" // Apply the custom class here
    >


      <Row

      >

        <Form
          name="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          // style={{ background: 'rgba(255, 255, 255, 1)', padding: '20px', borderRadius: '10px' }}
          layout='vertical'
        >

          <Form.Item
            name="email"
            label={<b>Email</b>}
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
            style={{ padding: "1px", margin: "1px" }}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Enter Your Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={<b>Password</b>}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            style={{ padding: "1px", margin: "1px" }}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center', padding: "1px", margin: "3px" }}>
            <Button style={{ backgroundColor: '#1B4F72', color: 'white', fontWeight: 'bold' }} htmlType="submit">
              Log in
            </Button>
       

          </Form.Item>
        </Form>


      </Row>
      {/* <div>
        <GoogleLogin
          clientId="YOUR_GOOGLE_CLIENT_ID"
          buttonText="Login with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </div> */}
    </Modal>

  </>
  );
}
export default Login;
