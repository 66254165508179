import React from 'react'
import AboutImage from './image/about.jpg'
export default function AbountUs() {
  return (
    <div className='text-justify bg-[#eaeaea] hyphens-auto  py-10' id='about'  data-aos="fade-up">
      <div className='m-auto text-center text-[30px] font-semibold'>About Us</div>
      <div className='flex sm:flex-row flex-col'>
        <div className='sm:w-[45%] w-[90%] m-auto sm:p-5 p-1'>
          <div>
            Welcome to MyVehicle, your comprehensive software solution provider for Transporters industry management. With our cutting-edge technology and innovative approach, we streamline and optimize every aspect of Vehicle Management, ensuring efficiency, accuracy, and reliability throughout the process.
            <br /> <br />
            At MyVehicle Solutions, we understand the unique challenges faced by Transport industry stakeholders. Our software suite addresses these challenges head-on, offering a range of features tailored to meet the specific needs of the industry.
          </div>
        </div>
        <div className='sm:w-[45%] w-[90%] m-auto sm:p-5 p-1 pt-10'>
         <img src={AboutImage} height={50}/>
        </div>
      </div>
    </div>
  )
}
