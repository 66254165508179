import React,{useState,useEffect} from 'react';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';

import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import './Dashboard.css'
export default function TripCharts({ chart_data }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(window.innerWidth > 768 ? 650 : 350);
  console.log(width, value)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setValue(window.innerWidth > 768 ? 500 : 300);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const series = [
    {
      type: 'bar',
      stack: '',
      yAxisKey: 'eco',
      data: [chart_data['jan'], chart_data['feb'], chart_data['mar'], chart_data['apr'], chart_data['may'], chart_data['jun'], chart_data['july'], chart_data['aug'], chart_data['sep'], chart_data['oct'], chart_data['nov'], chart_data['dec']],

    },

  ];

  return (
    <>
      {/* <h1 style={{textAlign:"center"}}>Month Trip Chart</h1> */}

      <ChartContainer
        series={series}
        width={value}
        height={280}
        className='ChartContainer'
        xAxis={[
          {
            id: 'years',
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            scaleType: 'band',
            valueFormatter: (value) => value.toString(),
          },
        ]}
        yAxis={[
          {
            id: 'eco',
            scaleType: 'linear',
          },
          {
            id: 'pib',
            scaleType: 'log',
          },
        ]}
      //   colors={'#1B4F72'}
      >

        <BarPlot color="#1B4F72" />
        <LinePlot color="#1B4F72" />
        <ChartsXAxis label="Month Trip Chart" position="bottom" axisId="years" />
        <ChartsYAxis label="Results" position="left" axisId="eco" />
        <ChartsYAxis label="PIB" position="right" axisId="pib" />
      </ChartContainer>

    </>
  );
}
