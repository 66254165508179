import React, { useState, useMemo, useEffect } from 'react';
import { Button, Input, Modal, Tooltip } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { IoEyeSharp } from "react-icons/io5";
import { updateVehicle } from '../../Services/Services';
const VehicleTire = ({ vehicleData, vehicleId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [arrow, setArrow] = useState('Show');
    const [editingRow, setEditingRow] = useState(null);
    const [editedData, setEditedData] = useState([]);
    const [currentEdit, setCurrentEdit] = useState({});

    console.log(vehicleId, 'vehicleId');

    useEffect(() => {
        setEditedData(vehicleData);
    }, [vehicleData]);

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') return false;
        if (arrow === 'Show') return true;
        return { pointAtCenter: true };
    }, [arrow]);

    const showModal = () => setIsModalOpen(true);
    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);

    const handleEdit = (index) => {
        setEditingRow(index);
        setCurrentEdit(editedData[index]);
    };

    const handleSave = async (index) => {
        const newData = [...editedData];
        newData[index] = currentEdit;
        setEditedData(newData);
        setEditingRow(null);
        const response = await updateVehicle(vehicleId,{'tire_details':newData,'tire_no':currentEdit.tire_no,'capacity':currentEdit.capacity});
    };

    const handleInputChange = (key, value) => {
        setCurrentEdit({
            ...currentEdit,
            [key]: value
        });
    };

    const buttonStyle = {
        backgroundColor: '#1B4F72',
        color: 'white',
        fontWeight: '600'
    };

    return (
        <>
            <Tooltip placement="top" title="Tire No" arrow={mergedArrow}>
                <Button onClick={showModal} style={buttonStyle} aria-label="View Tires">
                    <IoEyeSharp />
                </Button>
            </Tooltip>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal-close"
            >
                <div style={{ maxHeight: "80vh", overflowY: "auto", marginTop: "25px" }}>
                    <table id="my-table" className="table-container">
                        <thead className="w-full text-white">
                            <tr>
                                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
                                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Tire No</th>
                                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Capacity</th>
                                {localStorage.getItem('user_type') === 'Company' &&
                                    <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {editedData.map((e, index) => (
                                <tr key={index}>
                                    <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                                    <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                                        {editingRow === index ? (
                                            <Input
                                                value={currentEdit.tire_no}
                                                onChange={(ev) => handleInputChange('tire_no', ev.target.value)}
                                            />
                                        ) : (
                                            e.tire_no
                                        )}
                                    </td>
                                    <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                                        {editingRow === index ? (
                                            <Input
                                                value={currentEdit.capacity}
                                                onChange={(ev) => handleInputChange('capacity', ev.target.value)}
                                            />
                                        ) : (
                                            e.capacity
                                        )}
                                    </td>
                                    {localStorage.getItem('user_type') === 'Company' &&
                                        <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">
                                            {editingRow === index ? (
                                                <Button onClick={() => handleSave(index)} style={buttonStyle} aria-label="Save">
                                                    Save
                                                </Button>
                                            ) : (
                                                <Button onClick={() => handleEdit(index)} style={buttonStyle} aria-label="Edit">
                                                    <EditOutlined style={{ fontSize: '15px', color: 'white', padding: "1px" }} />
                                                </Button>
                                            )}
                                        </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
};

export default VehicleTire;











