import React, { useState, useMemo } from 'react';
import { Button, Form, Input, Modal, Spin, Tooltip, message, Row, Col, Radio, Select, } from "antd";
import { postVehicle } from '../../Services/Services';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { FaPlusSquare } from "react-icons/fa";
const { Option } = Select;

const AddNewVehicle = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numberOfInputs, setNumberOfInputs] = useState(0);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        const arrayOfObjects = Object.keys(values)
            .filter(key => Number.isInteger(parseInt(key, 10)))
            .map(key => ({ ['tire_no']: values[key] }));
        Object.assign(values, { tire_details: arrayOfObjects, com_id: localStorage.getItem('com_id'), vehicle_status: 'stop' })
        const response = await postVehicle(values);
        setLoading(false);
        if (response) {
            message.success('Vehicle Creation Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.vehicle_id - a.vehicle_id);
            props.setVehicle(sortedArray);
            props.setfilterVihilce(sortedArray)
            form.resetFields();
        } else {
            message.warning('Vehicle Creation Not Successful');
        }
    };
    const handleSelectChange = (value) => {
        setNumberOfInputs(value);
    };
    const renderInputFields = () => {
        const inputFields = [];
        for (let i = 1; i <= numberOfInputs; i++) {
            inputFields.push(
                <Col span={6}>

                    <Form.Item key={i} label={`Tire ${i}`} name={i}

                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Select No Of Tire!",
                            },
                        ]}
                    >
                        <Input placeholder='Enter Tire No' />
                    </Form.Item>
                </Col>

            );
        }
        return inputFields;
    };

    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Vehicle Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add New Vehicle'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
                width={1000}
                className="custom-modal-close"
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                name="vehicle_no"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Vehicle No!",
                                    },
                                ]}
                                label={<b>Vehicle No</b>}
                                style={{ padding: "1px", margin: "1px" }}
                            >
                                <Input placeholder="Enter Vehicle No" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="model_no"
                                label={<b>Model No</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Model No!",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Model No" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="chasis_no"
                                label={<b>Chasis No</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Chasis No!",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Chasis No" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="gps_avalibility"
                                label={<b>GPS</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Gps!",
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>

                                </Radio.Group>
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label={<b> No Of Tire</b>}
                                name="no_of_tire"
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select No Of Tire!",
                                    },
                                ]}
                            >
                                <Select showSearch
                                    placeholder='Select No Of Tire'
                                    onChange={handleSelectChange}
                                >
                                    {Array.from({ length: 99 }, (_, i) => i).map((num) => (
                                        <Option key={num} value={num}>{num}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        {renderInputFields()}
                    </Row>
                    {/* <Form.List name="names">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Form.Item
                {...restField}
                key={key}
                label="Name"
                name={[name, 'name']}
                fieldKey={[fieldKey, 'name']}
                rules={[{ required: true, message: 'Missing name' }]}
              >
                <Input />
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add Name
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List> */}
                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddNewVehicle;
