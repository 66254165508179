import React from 'react'
import Features from './Features'
import Contact from './Contact'
import AbountUs from './AbountUs'
import TopNavbar from '../LandingPage/Nav/TopNavbar'
import Webintro from './Webintro'
import Footer from './Footer'
export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Webintro />
      <div
        className='abc'
      >

        <AbountUs />

        <Features />

        <Contact />
      </div>
      <Footer/>
    </>
  );
}


