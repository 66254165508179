import React, { useState, useEffect, useMemo } from 'react';
import { getTrip, deleteTrip, profileDetails, getTripByProfileId ,getAllExpense} from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Popconfirm, Select } from 'antd';
import AddNewTrip from './AddNewTrip';
import EditTrip from './EditTrip';
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { DeleteOutlined } from '@ant-design/icons';
import { MdFileDownloadDone } from "react-icons/md";
import TripComplete from './TripComplete';
import PrintTrip from './PrintTrip';
export default function Trip() {
  const [trip, settrip] = useState([]);
  const [filterTrip, setfilterTrip] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const [profile, setProfile] = useState([])
  const [expenseList, setexpenseList] = useState([]);
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {

    if (localStorage.getItem('user_type') === 'Company') {
      GetTrip();
    } else {
      ProfileDetails()
    }
  }, []);

  const GetTrip = async () => {
    const response = await getTrip();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.trip_id - a.trip_id);
      settrip(sortedArray);
      setfilterTrip(sortedArray)
    }
  }
  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      setProfile(response)
    }
  }
  const GetTripByProfileId = async (id) => {
    const response = await getTripByProfileId(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.trip_id - a.trip_id);
      settrip(sortedArray);
      setfilterTrip(sortedArray)
    }
  }
  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterTrip.filter(item =>
      item.vehicle_no.toLowerCase().includes((allValues.vehicle_no === undefined ? '' : allValues.vehicle_no).toLowerCase()) &&
      item.driver_name.toLowerCase().includes((allValues.driver_name === undefined ? '' : allValues.driver_name).toLowerCase())
    );

    settrip(filteredData);

  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = trip.map((e, index) => [
      index + 1,
      e.vehicle_no,
      e.from_city,
      e.to_city,
      e.driver_name,
      e.consignee_name,
      e.consignee_contact,
      e.start_date,
      e.complete_date
    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Vehicle No', 'From City', 'To City', 'Driver Name', 'Consigneee Name', 'Consignee Contact', 'Start Date', 'End Date'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 10 },
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Trip List');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Trip List.xlsx');
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: `${"Complete Trip"}`,
  });
  const DeleteTrip = async (id) => {
    const response = await deleteTrip(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.trip_id - a.trip_id);
      settrip(sortedArray);
      setfilterTrip(sortedArray)

    }
  }
  const [tripDetails, setTripDetails] = useState({})
  const findTrip = (trip_id) => {
    setTripDetails(trip.find(o => o.trip_id === trip_id))
  }
  const CompleteTrip = async (trip_id) => {
    setTripDetails(trip.find(o => o.trip_id === trip_id))
    setIsModalVisible(true)

  }
  const GetTripExpenseByTripId = async (trip_id) => {
    const response = await getAllExpense({ trip_id: trip_id });
    if (response) {
      setTripDetails(trip.find(o => o.trip_id === trip_id))
      setexpenseList(response);
      generatePDF()
    }

  }
  return (
    <div >
      <div className="print_components" ref={conponentPDF} >
        <PrintTrip expenseList={expenseList} tripDetails={tripDetails}/>
      </div>
      <TripComplete
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        settrip={settrip}
        setfilterTrip={setfilterTrip}
        tripDetail={tripDetails}
      />
      {localStorage.getItem('user_type') === 'Company'
        ?
        <Form
          form={form}
          onValuesChange={handleValuesChange}
        >
          <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
            <Col>
              {/* <label><b>Party Name</b></label><br /> */}
              <Form.Item name='vehicle_no'>
                <Input
                  placeholder='Search by Vehicle No'
                />
              </Form.Item>
            </Col>

            <Col>
              {/* <label><b>Pan Number</b></label><br /> */}
              <Form.Item name='driver_name'>
                <Input
                  placeholder='Search by Driver Name'

                />
              </Form.Item>
            </Col>
            <Col>
              <AddNewTrip settrip={settrip} setfilterTrip={setfilterTrip} />
              <Tooltip
                placement="top"
                title={'Print'}
                arrow={mergedArrow}
              >
                <Button
                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                  }}
                  onClick={handlePrint}
                >
                  <IoIosPrint />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={'Excel'}
                arrow={mergedArrow}
              >
                <Button
                  style={{

                    // margin: "10px",
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: '600',


                  }}
                  onClick={handleExportToExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </Col>
          </div>
        </Form>
        :
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            <Select style={{ width: '200px' }}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={GetTripByProfileId}
            >
              {profile.map((i) => (
                <Select.Option value={i.id}>{i.name}</Select.Option>
              ))}

            </Select>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
          <br />
        </div>

      }
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className='print-container'>
        <table id="my-table" className="table-container"  >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              {localStorage.getItem('user_type') === 'Admin' &&
                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Trip Id</th>
              }
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Vehicle No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">From City</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">To City</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Driver Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Consignee Name</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Consignee Contact</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Start Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">End Date</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Action</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Complete</th>
            </tr>
          </thead>
          <tbody>
            {trip.map((e, index) => (
              <tr style={{ backgroundColor: e.is_complete ? '#b8ebb8' : '' }} >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                {localStorage.getItem('user_type') === 'Admin' &&
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.trip_id}</td>
                }
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.vehicle_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.from_city}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.to_city}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.driver_name}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.consignee_name}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.consignee_contact}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.start_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.complete_date}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  {e.is_complete ? '-' :
                    <>
                      <button onClick={() => findTrip(e.trip_id)}>
                        <EditTrip settrip={settrip} setfilterTrip={setfilterTrip} tripDetails={tripDetails} />
                      </button>
                      <Popconfirm
                        title="Delete Trip"
                        description="Are you sure to delete this trip?"
                        okText={<span style={{ color: "black" }}>Yes</span>}
                        cancelText="No"
                        onConfirm={() => {
                          DeleteTrip(e.trip_id);
                        }}
                        onCancel={() => {
                          // Handle cancellation if needed
                        }}
                      >
                        <button>
                          <DeleteOutlined
                            style={{
                              backgroundColor: '#1B4F72',
                              color: 'white',
                              fontWeight: '800',
                              padding: "8px",
                              fontSize: "15px",
                              borderRadius: "5px"
                            }}
                          />
                        </button>
                      </Popconfirm>
                    </>}
                </td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  {e.is_complete ?

                    <button

                      style={{
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"
                      }}
                      onClick={()=>GetTripExpenseByTripId(e.trip_id)}
                    >
                      <IoIosPrint />
                    </button>
                    : <Popconfirm
                      title="Complete Vehicle Repair"
                      description="Are You Sure To Complete Vehicle Trip?"
                      okText={<span style={{ color: "black" }}>Yes</span>}
                      cancelText="No"
                      onConfirm={() => {
                        CompleteTrip(e.trip_id);
                      }}
                      onCancel={() => {
                        // Handle cancellation if needed
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={'Complete Vehicle Trip'}
                        arrow={mergedArrow}
                      >
                        <button

                          style={{
                            backgroundColor: '#1B4F72',
                            color: 'white',
                            fontWeight: '800',
                            padding: "8px",
                            fontSize: "15px",
                            borderRadius: "5px"
                          }}
                        >
                          <MdFileDownloadDone />
                        </button>
                      </Tooltip>
                    </Popconfirm>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
