import React from 'react';
import {Row,Col,Card} from 'antd'
import './Features.css'
import './Travelcard.css'
import { GiBus } from "react-icons/gi";
import { LiaClipboardListSolid } from "react-icons/lia";
import { GiReceiveMoney } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";
import { LiaTruckSolid } from "react-icons/lia";
import { CgFileDocument } from "react-icons/cg";
import { MdOutlineCircleNotifications } from "react-icons/md";
import { LuArchiveRestore } from "react-icons/lu";
export default function Features() {
    return (
        <div className='text-justify bg-[#eaeaea] hyphens-auto  py-10' id='features'  data-aos="fade-up">
            <div className='m-auto text-[30px] font-semibold my-3' style={{textAlign:"center"}}>Our Services</div>
      <Row>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
            <GiReceiveMoney className='ICON' />
              
                    </div>
                    <div className="custom-card">
                        <br/>
                        <h4><b>Vehicle Expense Tracking</b></h4>
                        <br/>
                        <p> Easily log and monitor all vehicle-related expenses, including fuel, maintenance, repairs, and insurance. Generate detailed reports to analyze costs and optimize budget allocation.</p>
                    </div>
            </Card>
        </Col>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
           
                    <GiBus className='ICON'/>
                    </div>
                    <div className="custom-card">
                    <br/>
                        <h4><b>Trip Management</b></h4>
                        <br/>
                        <p> Plan and track trips efficiently with GPS integration, route optimization, and real-time monitoring. Keep records of trip details, mileage, and driver performance to ensure operational efficiency.</p>
                    </div>
            </Card>
        </Col>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
            <LiaTruckSolid className='ICON'/>
                    </div>
                    <div className="custom-card">
                
                    <br/>
                        <h4><b>Tire Management</b></h4>
                        <br/>
                        <p>Maintain tire health with automated reminders for rotations, pressure checks, and replacements. Track tire wear and purchase history to extend tire life and ensure safety.</p>
                    </div> 
            </Card>
        </Col>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
            <LiaClipboardListSolid className='ICON'/>
                    </div>
                    <div className="custom-card">
                    <br/>
                        <h4><b>Vehicle Document</b></h4>
                        <br/>
                        <p>Securely store all vehicle-related documents, including registration, insurance, maintenance records, and compliance certificates. Categorize and tag documents for easy retrieval.</p>
                    </div>
            </Card>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
                    <MdOutlineCircleNotifications className='ICON'/>
                    </div>
                    <div className="custom-card">
                    <br/>
                        <h4><b>Expiration Alerts</b></h4>
                        <br/>
                        <p>Set up automated alerts for document expirations such as insurance renewals, registration renewals, and inspection dates to ensure timely updates and compliance.</p>
                    </div>
            </Card>
        </Col>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
                    <CgFileDocument className='ICON'/>
                    </div>
                    <div className="custom-card">
                    <br/>
                        <h4><b>Upload and Scan</b></h4>
                        <br/>
                        <p> Easily upload documents directly from your device or scan physical documents using the app.Use to extract key information for quick reference.</p>
                    </div>
            </Card>
        </Col>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
                    <LuArchiveRestore className='ICON' />
                    </div>
                    <div className="custom-card">
                    <br/>
                        <h4><b>Audit Trails</b></h4>
                        <br/>
                        <p>Maintain a comprehensive history of document updates, modifications, and access to ensure transparency and accountability.</p>
                    </div> 
            </Card>
        </Col>
        <Col className='features_col'>
            <Card>
            <div className="custom-image">
                    <TbReportAnalytics className='ICON' />
                    </div>
                    <div className="custom-card">
                    <br/>
                        <h4><b>Fuel Management</b></h4>
                        <br/>
                        <p>Tracking fuel consumption to identify trends, inefficiencies, and potential issues.</p>
                        <br/>
                    </div>
            </Card>
        </Col>
      </Row>
        </div>
    )
}
