import React, { useState, useMemo } from 'react';
import { Button, Form, Input, Modal, Spin, message, Row, Col, Upload, Tooltip } from "antd";
import { postDriver } from '../../Services/Services';
import { FaPlusSquare } from "react-icons/fa";
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
const AddDriver = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        setLoading(true);
        Object.assign(values, { com_id: localStorage.getItem('com_id') })
        const response = await postDriver(values);
        setLoading(false);
        if (response) {
            message.success('Driver Creation Successful');
            setIsModalOpen(false);
            const sortedArray = [...response].sort((a, b) => b.driver_id - a.driver_id);
            props.setdriver(sortedArray);
            props.setdriverDetails(sortedArray)
            form.resetFields();
        } else {
            message.warning('Driver Creation Not Successful');
        }
    };

   
    return (
        <>
            <Modal
                open={loading}
                footer={null}
                closable={false}
                width={400}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin />
                    <h2>Driver Creating......</h2>
                </div>
            </Modal>
            <Tooltip
                placement="top"
                title={'Add New Driver'}
                arrow={mergedArrow}
            >
                <Button onClick={showModal}
                    style={{
                        //   margin:"10px",
                        backgroundColor: '#1B4F72',
                        color: 'white',
                        fontWeight: '600'
                    }}
                >

                    <FaPlusSquare />


                </Button>
            </Tooltip>
            <Modal open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                footer={null}
                // width={1000}
                className="custom-modal-close"
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="driver_name"
                                label={<b>Driver Name</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Driver Name!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Driver Name!' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="contact_no"
                                label={<b>Driver Contact No</b>}
                                style={{ padding: "1px", margin: "1px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Contact No!",
                                    },
                                ]}
                            >
                                <Input placeholder='Please Enter Contact No!' maxLength={10} minLength={10} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="license_no"
                        label={<b>License No</b>}
                        style={{ padding: "1px", margin: "1px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please Select License No!",
                            },
                        ]}
                    >
                        <Input placeholder='Enter License No' />
                    </Form.Item>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="license"
                                label={<label className='font-bold' style={{ fontSize: "14px" }}>Pic License</label>}
                                getValueFromEvent={({ file }) => file.originFileObj}

                            >
                                <Upload
                                    accept="image/*"  >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="aadhar_card"
                                label={<label className='font-bold' style={{ fontSize: "14px" }}>Upload Aadhar Card</label>}
                                getValueFromEvent={({ file }) => file.originFileObj}

                            >
                                <Upload
                                    accept="image/*" >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>



                        </Col>
                    </Row>
                    <Form.Item
                        name="address"
                        label={<b>Address</b>}
                        style={{ padding: "1px", margin: "1px" }}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item className="text-center" style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            style={{ color: 'white', backgroundColor: '#1B4F72', fontSize: 'medium' }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddDriver;
