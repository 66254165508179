import React, { useState, useEffect, useMemo } from 'react';
import { getDriver, deleteDriver, profileDetails, getDriverByProfileId } from '../../Services/Services';
import { Col, Input, Button, Form, Tooltip, Popconfirm, Modal, Image, Select } from 'antd';
import AddDriver from './AddDriver';
import EditDriver from './EditDriver';
import { FaFileExcel, FaDownload } from "react-icons/fa";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import * as XLSX from 'xlsx'; // Import xlsx library
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IoIosPrint } from "react-icons/io";
import { DeleteOutlined } from '@ant-design/icons';
import config from '../../config';
import { IoEyeSharp } from "react-icons/io5";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { CiMail } from "react-icons/ci";
export default function Driver() {
  const [driver, setdriver] = useState([]);
  const [filterDriver, setfilterDriver] = useState([]);
  const [form] = Form.useForm();
  const [arrow, setArrow] = useState('Show');
  const [profile, setProfile] = useState([])
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  useEffect(() => {
    if (localStorage.getItem('user_type') === 'Company') {
      GetDriver();
    } else {
      ProfileDetails()
    }
  }, []);

  const GetDriver = async () => {
    const response = await getDriver();
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.driver_id - a.driver_id);
      setdriver(sortedArray);
      setfilterDriver(sortedArray)
    }
  }
  const ProfileDetails = async () => {
    const response = await profileDetails();
    if (response) {
      setProfile(response)
    }
  }

  const GetDriverByProfileId = async (id) => {
    const response = await getDriverByProfileId(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.driver_id - a.driver_id);
      setdriver(sortedArray);
      setfilterDriver(sortedArray)
    }
  }
  const handleValuesChange = (changedValues, allValues) => {
    const filteredData = filterDriver.filter(item =>
      item.contact_no.toLowerCase().includes((allValues.contact_no === undefined ? '' : allValues.contact_no).toLowerCase()) &&
      item.driver_name.toLowerCase().includes((allValues.driver_name === undefined ? '' : allValues.driver_name).toLowerCase())
    );

    setdriver(filteredData);


  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define the data rows
    const wsData = driver.map((e, index) => [
      index + 1,
      e.driver_name,
      e.license_no,
      e.contact_no,
      e.address,
    ]);

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Sr. No', 'Driver Name', 'License No.', 'Contact No', 'Address'],
      ...wsData,
    ]);

    // Apply styles to the header row
    const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1'];
    headerCells.forEach((cell) => {
      worksheet[cell].s = {
        fill: { fgColor: { rgb: '#1B4F72' } }, // Yellow background
        font: { bold: true }, // Bold font
        alignment: { horizontal: 'center', vertical: 'center' } // Center alignment
      };
    });

    // Optional: Auto-width columns
    const wscols = [
      { wch: 10 },  
      { wch: 20 }, 
      { wch: 20 },  
      { wch: 20 },  
      { wch: 10 },  
      
    ];
    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Driver');

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'Driver List.xlsx');
  };


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const DeleteTrip = async (id) => {
    const response = await deleteDriver(id);
    if (response) {
      const sortedArray = [...response].sort((a, b) => b.driver_id - a.driver_id);
      setdriver(sortedArray);
      setfilterDriver(sortedArray)

    }
  }
  const [driverDetails, setdriverDetails] = useState({})
  const findDriver = (driver_id) => {
    setdriverDetails(driver.find(o => o.driver_id === driver_id))
  }
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false)
  };
  const [docPath, setdocpath] = useState('')
  const handleDocuments = (path) => {
    setOpen(true)
    setdocpath(path)
  }

  const shareByEmail = (imageUrl) => {
    const subject = 'Check out this image!';
    const body = 'Hey, I thought you might like this image!';
    const dataUrl = imageUrl;

    const mailToLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(dataUrl)}`;

    window.location.href = mailToLink;
  };
  return (
    <div >
      <Modal
        open={open}
        footer={null}
        onCancel={handleCancel}
        width={380}
      >
        <Image src={docPath} width={300} alt='img' />
        <div style={{ textAlign: "center" }}>
          <Tooltip
            placement="top"
            title={'Download'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => saveAs(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px",
                marginRight: "20px"
              }}
            >
              <FaDownload

              />
            </button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={'Mail Image'}
            arrow={mergedArrow}
          >
            <button

              onClick={() => shareByEmail(docPath)}
              style={{
                backgroundColor: '#1B4F72',
                color: 'white',
                fontWeight: '800',
                padding: "8px",
                fontSize: "15px",
                borderRadius: "5px"
              }}
            >
              <CiMail />
            </button>
          </Tooltip>
        </div>
      </Modal>
      {localStorage.getItem('user_type') === 'Company'
        ?
        <Form
          form={form}
          onValuesChange={handleValuesChange}
        >
          <div className=" grid grid-cols-1 sm:grid sm:grid-cols-3 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">


            <Col>
              {/* <label><b>Pan Number</b></label><br /> */}
              <Form.Item name='driver_name'>
                <Input
                  placeholder='Search by Driver Name'

                />
              </Form.Item>
            </Col>
            <Col>
              {/* <label><b>Party Name</b></label><br /> */}
              <Form.Item name='contact_no'>
                <Input
                  placeholder='Search by Contact No'
                />
              </Form.Item>
            </Col>
            <Col>
              <AddDriver setdriver={setdriver} setdriverDetails={setdriverDetails} />
              <Tooltip
                placement="top"
                title={'Print'}
                arrow={mergedArrow}
              >
                <Button
                  style={{
                    backgroundColor: '#1B4F72',
                    color: 'white',
                    fontWeight: '600'
                  }}
                  onClick={handlePrint}
                >
                  <IoIosPrint />
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={'Excel'}
                arrow={mergedArrow}
              >
                <Button
                  style={{

                    // margin: "10px",
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: '600',


                  }}
                  onClick={handleExportToExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </Col>
          </div>
        </Form>
        :
        <div className=" grid grid-cols-1 sm:grid sm:grid-cols-2 sm:gap-2 m-auto px-5 sm:w-[100%]  md:w-[100%]">
          <Col>
            <Select style={{ width: '200px' }}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={GetDriverByProfileId}
            >
              {profile.map((i) => (
                <Select.Option value={i.id}>{i.name}</Select.Option>
              ))}

            </Select>
          </Col>
          <Col>

            <Tooltip
              placement="top"
              title={'Print'}
              arrow={mergedArrow}
            >
              <Button
                style={{
                  backgroundColor: '#1B4F72',
                  color: 'white',
                  fontWeight: '600'
                }}
                onClick={handlePrint}
              >
                <IoIosPrint />
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={'Excel'}
              arrow={mergedArrow}
            >
              <Button
                style={{

                  // margin: "10px",
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: '600',


                }}
                onClick={handleExportToExcel}
              >
                <FaFileExcel />
              </Button>
            </Tooltip>
          </Col>
          <br />
        </div>

      }
      <div style={{ maxHeight: "80vh", overflowY: "auto" }} ref={componentRef} className="print-container">
        <table id="my-table" className="table-container"  >
          <thead className="w-full text-white">
            <tr>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Sr. No</th>
              {localStorage.getItem('user_type') === 'Admin' &&
                <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Driver Id</th>
              }
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Driver Name</th>

              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">License No</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-2 px-1 lg:p-1 sm:text-base">Contact</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">Address</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Aadhar Card</th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>License </th>
              <th className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>Action</th>
            </tr>
          </thead>
          <tbody>
            {driver.map((e, index) => (
              <tr >
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{index + 1}</td>
                {localStorage.getItem('user_type') === 'Admin' &&
                  <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.driver_id}</td>
                }
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.driver_name}</td>

                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.license_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.contact_no}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base">{e.address}</td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                 

                     
                <Tooltip
                    placement="top"
                    title={e.aadhar_card===null ? '' :'View Aadhar Card'}
                    arrow={mergedArrow}
                  >
                    <Button
                      onClick={() => handleDocuments(e.aadhar_card)}
                      style={{
                        backgroundColor: e.aadhar_card===null ? 'white' :'#1B4F72',
                        color: e.aadhar_card===null ? 'black' :'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"
                      
                      }}
                      disabled={e.aadhar_card===null ? true :false}
                    >
                      <IoEyeSharp

                      />
                    </Button>
                  </Tooltip>
                </td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                   
                <Tooltip
                    placement="top"
                    title={e.license===null ? '' :'View License'}
                    arrow={mergedArrow}
                  >
                    <Button
                      onClick={() => handleDocuments(e.license)}
                      style={{
                        backgroundColor: e.license===null ? 'white' :'#1B4F72',
                        color: e.license===null ? 'black' :'white',
                        fontWeight: '800',
                        padding: "8px",
                        fontSize: "15px",
                        borderRadius: "5px"
                      
                      }}
                      disabled={e.license===null ? true :false}
                    >
                      <IoEyeSharp

                      />
                    </Button>
                  </Tooltip>
                </td>
                <td className="lg:text-[12px] text-[0.41rem] p-0 sm:px-3 px-1 lg:p-1 sm:text-base" id='col_visible'>
                  <Tooltip
                    placement="top"
                    title={'Edit Driver Documents'}
                    arrow={mergedArrow}
                  >
                    <button onClick={() => findDriver(e.driver_id)}>
                      <EditDriver setdriver={setdriver} setfilterDriver={setfilterDriver} driverDetails={driverDetails} />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={'Delete Driver Details'}
                    arrow={mergedArrow}
                  >
                    <Popconfirm
                      title="Delete Driver"
                      description="Are you sure to delete this Driver?"
                      okText={<span style={{ color: "black" }}>Yes</span>}
                      cancelText="No"
                      onConfirm={() => {
                        DeleteTrip(e.driver_id);
                      }}
                      onCancel={() => {
                        // Handle cancellation if needed
                      }}
                    >
                      <button>
                        <DeleteOutlined
                          style={{
                            backgroundColor: '#1B4F72',
                            color: 'white',
                            fontWeight: '800',
                            padding: "8px",
                            fontSize: "15px",
                            borderRadius: "5px"
                          }}
                        />
                      </button>
                    </Popconfirm>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print-footer">
          Powered By Yashvitech IT Solution Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
